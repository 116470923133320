export const apes = [
  { code: '0122Z', label: 'Culture de fruits tropicaux et subtropicaux' },
  { code: '0130Z', label: 'Reproduction de plantes' },
  { code: '0150Z', label: 'Culture et élevage associés' },
  {
    code: '0812Z',
    label:
      "Exploitation de gravières et sablières, extraction d'argiles et de kaolin",
  },
  {
    code: '1012Z',
    label: 'Transformation et conservation de la viande de volaille',
  },
  {
    code: '1020Z',
    label:
      'Transformation et conservation de poisson, de crustacés et de mollusques',
  },
  { code: '1039B', label: 'Transformation et conservation de fruits' },
  { code: '1051A', label: 'Fabrication de lait liquide et de produits frais' },
  { code: '1052Z', label: 'Fabrication de glaces et sorbets' },
  { code: '1061B', label: 'Autres activités du travail des grains' },
  {
    code: '1071A',
    label: 'Fabrication industrielle de pain et de pâtisserie fraîche',
  },
  { code: '1071C', label: 'Boulangerie et boulangerie-pâtisserie' },
  {
    code: '1072Z',
    label: 'Fabrication de biscuits, biscottes et pâtisseries de conservation',
  },
  { code: '1081Z', label: 'Fabrication de sucre' },
  { code: '1083Z', label: 'Transformation du thé et du café' },
  { code: '1084Z', label: 'Fabrication de condiments et assaisonnements' },
  { code: '1091Z', label: "Fabrication d'aliments pour animaux de ferme" },
  { code: '1101Z', label: 'Production de boissons alcooliques distillées' },
  { code: '1107A', label: 'Industrie des eaux de table' },
  { code: '1721A', label: 'Fabrication de carton ondulé' },
  { code: '1812Z', label: 'Autre imprimerie (labeur)' },
  { code: '1813Z', label: 'Activités de pré-presse' },
  {
    code: '2030Z',
    label: 'Fabrication de peintures, vernis, encres et mastics',
  },
  {
    code: '2041Z',
    label: "Fabrication de savons, détergents et produits d'entretien",
  },
  {
    code: '2042Z',
    label: 'Fabrication de parfums et de produits pour la toilette',
  },
  { code: '2312Z', label: 'Façonnage et transformation du verre plat' },
  { code: '2351Z', label: 'Fabrication de ciment' },
  {
    code: '2361Z',
    label: "Fabrication d'éléments en béton pour la construction",
  },
  {
    code: '2511Z',
    label: 'Fabrication de structures métalliques et de parties de structures',
  },
  { code: '2512Z', label: 'Fabrication de portes et fenêtres en métal' },
  {
    code: '2825Z',
    label: "Fabrication d'équipements aérauliques et frigorifiques industriels",
  },
  { code: '3012Z', label: 'Construction de bateaux de plaisance' },
  { code: '3102Z', label: 'Fabrication de meubles de cuisine' },
  { code: '3109A', label: "Fabrication de sièges d'ameublement d'intérieur" },
  { code: '3250B', label: 'Fabrication de lunettes' },
  { code: '3312Z', label: 'Réparation de machines et équipements mécaniques' },
  { code: '3314Z', label: "Réparation d'équipements électriques" },
  {
    code: '3316Z',
    label: "Réparation et maintenance d'aéronefs et d'engins spatiaux",
  },
  {
    code: '3320D',
    label:
      "Installation d'équipements électriques, de matériels électroniques et optiques ou d'autres matériels",
  },
  { code: '3511Z', label: "Production d'électricité" },
  { code: '3522Z', label: 'Distribution de combustibles gazeux par conduites' },
  {
    code: '3530Z',
    label: "Production et distribution de vapeur et d'air conditionné",
  },
  { code: '3811Z', label: 'Collecte des déchets non dangereux' },
  {
    code: '3821Z',
    label: 'Traitement et élimination des déchets non dangereux',
  },
  { code: '3832Z', label: 'Récupération de déchets triés' },
  { code: '4110A', label: 'Promotion immobilière de logements' },
  { code: '4110C', label: "Promotion immobilière d'autres bâtiments" },
  { code: '4120A', label: 'Construction de maisons individuelles' },
  { code: '4120B', label: "Construction d'autres bâtiments" },
  { code: '4211Z', label: 'Construction de routes et autoroutes' },
  {
    code: '4299Z',
    label: "Construction d'autres ouvrages de génie civil n.c.a.",
  },
  {
    code: '4312A',
    label: 'Travaux de terrassement courants et travaux préparatoires',
  },
  { code: '4313Z', label: 'Forages et sondages' },
  {
    code: '4321A',
    label: "Travaux d'installation électrique dans tous locaux",
  },
  {
    code: '4322B',
    label:
      "Travaux d'installation d'équipements thermiques et de climatisation",
  },
  { code: '4329B', label: "Autres travaux d'installation n.c.a." },
  { code: '4332B', label: 'Travaux de menuiserie métallique et serrurerie' },
  { code: '4333Z', label: 'Travaux de revêtement des sols et des murs' },
  { code: '4334Z', label: 'Travaux de peinture et vitrerie' },
  { code: '4339Z', label: 'Autres travaux de finition' },
  { code: '4399A', label: "Travaux d'étanchéification" },
  {
    code: '4399C',
    label: 'Travaux de maçonnerie générale et gros œuvre de bâtiment',
  },
  {
    code: '4511Z',
    label: 'Commerce de voitures et de véhicules automobiles légers',
  },
  {
    code: '4520A',
    label: 'Entretien et réparation de véhicules automobiles légers',
  },
  { code: '4531Z', label: "Commerce de gros d'équipements automobiles" },
  { code: '4532Z', label: "Commerce de détail d'équipements automobiles" },
  {
    code: '4619B',
    label: 'Autres intermédiaires du commerce en produits divers',
  },
  {
    code: '4621Z',
    label:
      "Commerce de gros de céréales, de tabac non manufacturé, de semences et d'aliments pour le bétail",
  },
  { code: '4634Z', label: 'Commerce de gros de boissons' },
  { code: '4638B', label: 'Commerce de gros alimentaire spécialisé divers' },
  { code: '4639B', label: 'Commerce de gros alimentaire non spécialisé' },
  { code: '4642Z', label: "Commerce de gros d'habillement et de chaussures" },
  {
    code: '4645Z',
    label: 'Commerce de gros de parfumerie et de produits de beauté',
  },
  { code: '4646Z', label: 'Commerce de gros de produits pharmaceutiques' },
  {
    code: '4648Z',
    label: "Commerce de gros d'articles d'horlogerie et de bijouterie",
  },
  { code: '4649Z', label: "Commerce de gros d'autres biens domestiques" },
  { code: '4661Z', label: 'Commerce de gros de matériel agricole' },
  { code: '4662Z', label: 'Commerce de gros de machines-outils' },
  {
    code: '4663Z',
    label:
      "Commerce de gros de machines pour l'extraction, la construction et le génie civil",
  },
  {
    code: '4666Z',
    label: "Commerce de gros d'autres machines et équipements de bureau",
  },
  { code: '4669A', label: 'Commerce de gros de matériel électrique' },
  {
    code: '4669B',
    label: 'Commerce de gros de fournitures et équipements industriels divers',
  },
  {
    code: '4669C',
    label:
      'Commerce de gros de fournitures et équipements divers pour le commerce et les services',
  },
  {
    code: '4671Z',
    label: 'Commerce de gros de combustibles et de produits annexes',
  },
  { code: '4672Z', label: 'Commerce de gros de minerais et métaux' },
  {
    code: '4673B',
    label:
      "Commerce de gros d'appareils sanitaires et de produits de décoration",
  },
  { code: '4675Z', label: 'Commerce de gros de produits chimiques' },
  { code: '4676Z', label: "Commerce de gros d'autres produits intermédiaires" },
  { code: '4690Z', label: 'Commerce de gros non spécialisé' },
  { code: '4711C', label: 'Supérettes' },
  { code: '4711D', label: 'Supermarchés' },
  { code: '4711F', label: 'Hypermarchés' },
  {
    code: '4730Z',
    label: 'Commerce de détail de carburants en magasin spécialisé',
  },
  {
    code: '4741Z',
    label:
      "Commerce de détail d'ordinateurs, d'unités périphériques et de logiciels en magasin spécialisé",
  },
  {
    code: '4742Z',
    label:
      'Commerce de détail de matériels de télécommunication en magasin spécialisé',
  },
  {
    code: '4752A',
    label:
      'Commerce de détail de quincaillerie, peintures et verres en petites surfaces (moins de 400 m2)',
  },
  {
    code: '4752B',
    label:
      'Commerce de détail de quincaillerie, peintures et verres en grandes surfaces (400 m2et plus)',
  },
  { code: '4759A', label: 'Commerce de détail de meubles' },
  { code: '4759B', label: "Commerce de détail d'autres équipements du foyer" },
  {
    code: '4762Z',
    label: 'Commerce de détail de journaux et papeterie en magasin spécialisé',
  },
  {
    code: '4764Z',
    label: "Commerce de détail d'articles de sport en magasin spécialisé",
  },
  {
    code: '4771Z',
    label: "Commerce de détail d'habillement en magasin spécialisé",
  },
  {
    code: '4773Z',
    label:
      'Commerce de détail de produits pharmaceutiques en magasin spécialisé',
  },
  {
    code: '4774Z',
    label:
      "Commerce de détail d'articles médicaux et orthopédiques en magasin spécialisé",
  },
  {
    code: '4775Z',
    label:
      'Commerce de détail de parfumerie et de produits de beauté en magasin spécialisé',
  },
  {
    code: '4776Z',
    label:
      'Commerce de détail de fleurs, plantes, graines, engrais, animaux de compagnie et aliments pour ces animaux en magasin spécialisé',
  },
  {
    code: '4777Z',
    label:
      "Commerce de détail d'articles d'horlogerie et de bijouterie en magasin spécialisé",
  },
  { code: '4778A', label: "Commerces de détail d'optique" },
  { code: '4778C', label: 'Autres commerces de détail spécialisés divers' },
  { code: '4791A', label: 'Vente à distance sur catalogue général' },
  { code: '4791B', label: 'Vente à distance sur catalogue spécialisé' },
  { code: '4932Z', label: 'Transports de voyageurs par taxis' },
  { code: '4939B', label: 'Autres transports routiers de voyageurs' },
  { code: '4941A', label: 'Transports routiers de fret interurbains' },
  { code: '4941B', label: 'Transports routiers de fret de proximité' },
  { code: '4942Z', label: 'Services de déménagement' },
  { code: '5010Z', label: 'Transports maritimes et côtiers de passagers' },
  { code: '5020Z', label: 'Transports maritimes et côtiers de fret' },
  { code: '5110Z', label: 'Transports aériens de passagers' },
  { code: '5210B', label: 'Entreposage et stockage non frigorifique' },
  { code: '5222Z', label: 'Services auxiliaires des transports par eau' },
  { code: '5223Z', label: 'Services auxiliaires des transports aériens' },
  { code: '5224A', label: 'Manutention portuaire' },
  { code: '5229A', label: 'Messagerie, fret express' },
  { code: '5229B', label: 'Affrètement et organisation des transports' },
  {
    code: '5310Z',
    label:
      "Activités de poste dans le cadre d'une obligation de service universel",
  },
  { code: '5510Z', label: 'Hôtels et hébergement similaire' },
  {
    code: '5520Z',
    label: 'Hébergement touristique et autre hébergement de courte durée',
  },
  { code: '5610A', label: 'Restauration traditionnelle' },
  { code: '5610C', label: 'Restauration de type rapide' },
  { code: '5813Z', label: 'Édition de journaux' },
  { code: '5819Z', label: "Autres activités d'édition" },
  { code: '5829A', label: 'Édition de logiciels système et de réseau' },
  { code: '5829C', label: 'Edition de logiciels applicatifs' },
  {
    code: '5911A',
    label: 'Production de films et de programmes pour la télévision',
  },
  {
    code: '5911B',
    label: 'Production de films institutionnels et publicitaires',
  },
  { code: '5914Z', label: 'Projection de films cinématographiques' },
  { code: '6020B', label: 'Edition de chaînes thématiques' },
  { code: '6110Z', label: 'Télécommunications filaires' },
  { code: '6120Z', label: 'Télécommunications sans fil' },
  { code: '6201Z', label: 'Programmation informatique' },
  { code: '6202A', label: 'Conseil en systèmes et logiciels informatiques' },
  {
    code: '6202B',
    label: 'Tierce maintenance de systèmes et d’applications informatiques',
  },
  { code: '6203Z', label: "Gestion d'installations informatiques" },
  { code: '6209Z', label: 'Autres activités informatiques' },
  {
    code: '6311Z',
    label: 'Traitement de données, hébergement et activités connexes',
  },
  { code: '6419Z', label: 'Autres intermédiations monétaires' },
  { code: '6420Z', label: 'Activités des sociétés holding' },
  {
    code: '6430Z',
    label: 'Fonds de placement et entités financières similaires',
  },
  { code: '6492Z', label: 'Autre distribution de crédit' },
  {
    code: '6499Z',
    label:
      'Autres activités des services financiers, hors assurance et caisses de retraite, n.c.a.',
  },
  { code: '6512Z', label: 'Autres assurances' },
  {
    code: '6619B',
    label:
      'Autres activités auxiliaires de services financiers, hors assurance et caisses de retraite, n.c.a.',
  },
  { code: '6622Z', label: "Activités des agents et courtiers d'assurances" },
  { code: '6630Z', label: 'Gestion de fonds' },
  { code: '6810Z', label: 'Activités des marchands de biens immobiliers' },
  { code: '6820A', label: 'Location de logements' },
  {
    code: '6820B',
    label: "Location de terrains et d'autres biens immobiliers",
  },
  { code: '6831Z', label: 'Agences immobilières' },
  { code: '6831Z', label: 'Agences immobilières' },
  {
    code: '6832A',
    label: "Administration d'immeubles et autres biens immobiliers",
  },
  { code: '6910Z', label: 'Activités juridiques' },
  { code: '6920Z', label: 'Activités comptables' },
  { code: '7010Z', label: 'Activités des sièges sociaux' },
  { code: '7021Z', label: 'Conseil en relations publiques et communication' },
  {
    code: '7022Z',
    label: 'Conseil pour les affaires et autres conseils de gestion',
  },
  { code: '7112A', label: 'Activité des géomètres' },
  { code: '7112B', label: 'Ingénierie, études techniques' },
  { code: '7120A', label: 'Contrôle technique automobile' },
  { code: '7311Z', label: 'Activités des agences de publicité' },
  { code: '7312Z', label: 'Régie publicitaire de médias' },
  { code: '7490A', label: 'Activité des économistes de la construction' },
  { code: '7490B', label: 'Activité des géomètres-topographes' },
  { code: '7490C', label: 'Activités spécialisées de design' },
  { code: '7490D', label: 'Activités photographiques' },
  {
    code: '7490E',
    label: 'Activités spécialisées, scientifiques et techniques diverses',
  },
  {
    code: '7711A',
    label: 'Location et location-bail de véhicules automobiles légers',
  },
  { code: '7711B', label: 'Location et location-bail de camions' },
  {
    code: '7711C',
    label: "Location et location-bail d'autres véhicules automobiles",
  },
  {
    code: '7721Z',
    label: "Location et location-bail d'articles de loisirs et de sport",
  },
  {
    code: '7722Z',
    label: 'Location et location-bail de vidéocassette et disques vidéo',
  },
  {
    code: '7729Z',
    label: "Location et location-bail d'autres biens personnels et domestiques",
  },
  {
    code: '7731Z',
    label: 'Location et location-bail de machines et équipements agricoles',
  },
  {
    code: '7732Z',
    label:
      'Location et location-bail de machines et équipements pour la construction',
  },
  {
    code: '7733Z',
    label:
      'Location et location-bail de machines de bureau et de matériel informatique',
  },
  {
    code: '7734Z',
    label: 'Location et location-bail de matériels de transport aérien',
  },
  {
    code: '7735Z',
    label: 'Location et location-bail de matériels de transport par eau',
  },
  {
    code: '7739Z',
    label:
      "Location et location-bail d'autres machines, équipements et biens matériels n.c.a.",
  },
  {
    code: '7740Z',
    label:
      "Location et location-bail de propriété intellectuelle et de produits similaires, à l'exception des œuvres soumises à copyright",
  },
  {
    code: '7810Z',
    label: "Activités des agences de placement de main-d'œuvre",
  },
  { code: '7820Z', label: 'Activités des agences de travail temporaire' },
  { code: '7830Z', label: 'Autre mise à disposition de ressources humaines' },
  { code: '7911Z', label: 'Activités des agences de voyage' },
  { code: '7912Z', label: 'Activités des voyagistes' },
  { code: '7912Z', label: 'Activités des voyagistes' },
  {
    code: '7990Z',
    label: 'Autres services de réservation et activités connexes',
  },
  { code: '8010Z', label: 'Enseignement primaire' },
  { code: '8020Z', label: 'Enseignement secondaire général' },
  {
    code: '8030Z',
    label: 'Enseignement secondaire technique ou professionnel',
  },
  { code: '8041Z', label: 'Enseignement supérieur' },
  { code: '8042Z', label: 'Enseignement supérieur technique ou professionnel' },
  { code: '8510Z', label: 'Enseignement pré-primaire' },
  { code: '8520Z', label: 'Enseignement primaire' },
  { code: '8531Z', label: 'Enseignement secondaire général' },
  {
    code: '8532Z',
    label: 'Enseignement secondaire technique ou professionnel',
  },
  { code: '8541Z', label: 'Enseignement supérieur' },
  { code: '8542Z', label: 'Enseignement supérieur technique ou professionnel' },
  {
    code: '8551Z',
    label: "Enseignement de disciplines sportives et d'activités de loisirs",
  },
  { code: '8552Z', label: 'Enseignement culturel' },
  { code: '8553Z', label: 'Enseignement de la conduite' },
  { code: '8559A', label: "Formation continue d'adultes" },
  { code: '8559B', label: 'Autres enseignements' },
  { code: '8560Z', label: "Activités de soutien à l'enseignement" },
  { code: '8610Z', label: 'Activités hospitalières' },
  { code: '8621Z', label: "Laboratoires d'analyses médicales" },
  { code: '8622A', label: 'Activités de radiodiagnostic' },
  { code: '8622B', label: 'Activités des médecins généralistes' },
  { code: '8623Z', label: 'Pratique dentaire' },
  { code: '8690E', label: "Laboratoires d'analyses médicales" },
  { code: '8690F', label: 'Activités de radiodiagnostic et de radiothérapie' },
  { code: '8690G', label: 'Activités des infirmiers et des sages-femmes' },
  {
    code: '8690H',
    label:
      "Activités des professionnels de la rééducation, de l'appareillage et des pédicures-podologues",
  },
  { code: '8690J', label: 'Hémodialyse' },
  { code: '8690K', label: 'Soins de beauté' },
  { code: '8690L', label: 'Hémodialyse' },
  { code: '8690M', label: 'Activités de santé humaine non classées ailleurs' },
  { code: '8690N', label: 'Action sociale sans hébergement n.c.a.' },
  { code: '8690P', label: 'Hémodialyse' },
  { code: '8690Q', label: 'Activités de santé humaine non classées ailleurs' },
  { code: '8690R', label: 'Action sociale sans hébergement n.c.a.' },
  { code: '8690T', label: 'Action sociale sans hébergement n.c.a.' },
  { code: '8690V', label: 'Activités de santé humaine non classées ailleurs' },
  { code: '8690W', label: 'Activités de santé humaine non classées ailleurs' },
  { code: '8690X', label: 'Hémodialyse' },
  { code: '8690Y', label: 'Hémodialyse' },
  { code: '8690Z', label: 'Action sociale sans hébergement n.c.a.' },
  { code: '8710A', label: 'Hébergement médicalisé pour personnes âgées' },
  { code: '8710B', label: 'Hébergement médicalisé pour enfants handicapés' },
  {
    code: '8720A',
    label:
      'Hébergement social pour personnes handicapées mentales et malades mentales',
  },
  {
    code: '8720B',
    label:
      'Hébergement social pour adultes et familles en difficultés et autre hébergement social',
  },
  { code: '8730A', label: 'Hébergement social pour personnes âgées' },
  { code: '8730B', label: 'Hébergement social pour handicapés physiques' },
  { code: '8790A', label: 'Hébergement social pour enfants en difficultés' },
  {
    code: '8790B',
    label:
      'Hébergement social pour adultes et familles en difficultés et autre hébergement social',
  },
  { code: '8810A', label: 'Aide à domicile' },
  { code: '8810B', label: 'Accueil de jeunes enfants' },
  { code: '8810C', label: 'Aide à domicile' },
  { code: '8810D', label: 'Accueil de jeunes enfants' },
  { code: '8891A', label: 'Accueil de jeunes enfants' },
  { code: '8891B', label: 'Accueil de jeunes enfants' },
  { code: '8891C', label: 'Aide à domicile' },
  { code: '8891D', label: 'Aide à domicile' },
  {
    code: '8899A',
    label:
      "Autre accueil ou accompagnement sans hébergement d'adultes et de jeunes enfants",
  },
  { code: '8899B', label: 'Action sociale sans hébergement n.c.a.' },
  { code: '9001Z', label: 'Arts du spectacle vivant' },
  { code: '9002Z', label: 'Activités de soutien au spectacle vivant' },
  { code: '9003A', label: 'Création artistique relevant des arts plastiques' },
  { code: '9003B', label: 'Autre création artistique' },
  { code: '9003C', label: 'Autre création artistique' },
  { code: '9004Z', label: 'Gestion de salles de spectacles' },
  { code: '9101Z', label: 'Gestion des bibliothèques et des archives' },
  { code: '9102Z', label: 'Gestion des musées' },
  {
    code: '9103Z',
    label:
      'Gestion des sites et monuments historiques et des attractions touristiques similaires',
  },
  {
    code: '9104Z',
    label:
      'Gestion des jardins botaniques et zoologiques et des réserves naturelles',
  },
  { code: '9200Z', label: "Organisation de jeux de hasard et d'argent" },
  { code: '9311Z', label: "Gestion d'installations sportives" },
  { code: '9312Z', label: 'Activités de clubs de sports' },
  { code: '9313Z', label: 'Activités des centres de culture physique' },
  { code: '9319Z', label: 'Autres activités liées au sport' },
  {
    code: '9321Z',
    label: "Activités des parcs d'attractions et parcs à thèmes",
  },
  { code: '9329Z', label: 'Autres activités récréatives et de loisirs' },
  {
    code: '9411Z',
    label:
      'Gestion des sites et monuments historiques et des attractions touristiques similaires',
  },
  { code: '9412Z', label: 'Activités des musées' },
  { code: '9420Z', label: 'Activités des bibliothèques et archives' },
  {
    code: '9491Z',
    label:
      'Gestion des jardins botaniques et zoologiques et des réserves naturelles',
  },
  { code: '9492Z', label: "Organisation de jeux de hasard et d'argent" },
  {
    code: '9499Z',
    label: 'Autres organisations fonctionnant par adhésion volontaire',
  },
  {
    code: '9511Z',
    label: "Réparation d'ordinateurs et de biens personnels et domestiques",
  },
  { code: '9512Z', label: 'Réparation de produits électroniques grand public' },
  { code: '9521Z', label: 'Réparation de produits électroniques grand public' },
  { code: '9522Z', label: 'Réparation de produits électroniques grand public' },
  { code: '9523Z', label: 'Réparation de produits électroniques grand public' },
  { code: '9524Z', label: 'Réparation de produits électroniques grand public' },
  { code: '9525Z', label: 'Réparation de produits électroniques grand public' },
  { code: '9529Z', label: 'Réparation de produits électroniques grand public' },
  {
    code: '9531Z',
    label: "Réparation d'ordinateurs et d'équipements périphériques",
  },
  { code: '9532Z', label: 'Réparation de produits électroniques grand public' },
  { code: '9533Z', label: 'Réparation de produits électroniques grand public' },
  { code: '9601A', label: 'Blanchisserie-teinturerie de gros' },
  { code: '9601B', label: 'Blanchisserie-teinturerie de gros' },
  { code: '9602A', label: 'Coiffure' },
  { code: '9602B', label: 'Soins de beauté' },
  { code: '9602C', label: 'Soins de beauté' },
  { code: '9603Z', label: 'Pompes funèbres et activités connexes' },
  { code: '9604Z', label: 'Entretien corporel' },
  { code: '9609Z', label: 'Blanchisserie-teinturerie de gros' },
  { code: '9611Z', label: 'Blanchisserie-teinturerie de gros' },
  { code: '9612A', label: 'Blanchisserie-teinturerie de gros' },
  { code: '9612B', label: 'Blanchisserie-teinturerie de gros' },
  { code: '9621A', label: 'Blanchisserie-teinturerie de gros' },
  { code: '9621B', label: 'Blanchisserie-teinturerie de gros' },
  { code: '9621C', label: 'Blanchisserie-teinturerie de gros' },
  { code: '9621D', label: 'Blanchisserie-teinturerie de gros' },
  { code: '9629Z', label: 'Autres services personnels n.c.a.' },
  { code: '9631Z', label: 'Blanchisserie-teinturerie de gros' },
  { code: '9632Z', label: 'Blanchisserie-teinturerie de gros' },
  { code: '9633Z', label: 'Blanchisserie-teinturerie de gros' },
  { code: '9634Z', label: 'Blanchisserie-teinturerie de gros' },
  { code: '9635Z', label: 'Blanchisserie-teinturerie de gros' },
  { code: '9636Z', label: 'Blanchisserie-teinturerie de gros' },
  { code: '9700Z', label: 'Blanchisserie-teinturerie de gros' },
  { code: '9800Z', label: 'Blanchisserie-teinturerie de gros' },
  { code: '9810Z', label: 'Blanchisserie-teinturerie de gros' },
  { code: '9820Z', label: 'Blanchisserie-teinturerie de gros' },
  { code: '9900Z', label: 'Blanchisserie-teinturerie de gros' },
  { code: '9999Z', label: 'Blanchisserie-teinturerie de gros' },
];
