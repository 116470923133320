import { AbstractControl } from "@angular/forms";

export const validatorSiretNumber = (c: AbstractControl): { [key: string]: boolean } | null => {
    const regex = new RegExp(/\d{14}/g);
    if (!regex.test(c.value)) {
        return { 'errorSiretNumber': true };
    }
    return null;
}

export const validatorPhoneFrNumber = (c: AbstractControl): { [key: string]: boolean } | null => {
  if (c.value === '') {
    return null;
  }

  const regex = new RegExp(/^(?:(?:(?:\+|00)33[ ]?(?:\(0\)[ ]?)?)|0){1}[1-9]{1}([ .-]?)(?:\d{2}\1?){3}\d{2}$/gm);
    if (!regex.test(c.value)) {
        return { 'errorPhoneFrNumber': true };
    }
    return null;
}

export const validatorEmail = (c: AbstractControl): { [key: string]: boolean } | null => {
    const regex = new RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
    if (!regex.test(c.value)) {
        return { 'errorEmail': true };
    }
    return null;
}
