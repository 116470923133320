import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RegisterCompanyComponent } from './components/register-company/register-company.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CanvasCustomComponent } from './components/canvas-custom/canvas-custom.component';
import { HttpClientModule } from '@angular/common/http';
import { RegisterAssociationComponent } from './components/register-association/register-association.component';
import { RegisterSeniorComponent } from './components/register-senior/register-senior.component';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { PaymentComponent } from './components/payment/payment.component';
import { PriceTableComponent } from './components/price-table/price-table.component';

const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'company', component: RegisterCompanyComponent},
  {path: 'association', component: RegisterAssociationComponent},
  {path: 'senior', component: RegisterSeniorComponent},
  {path: 'paiement/:status', component: PaymentComponent},
  {path: 'recapitulatif', component: PaymentComponent},
];

@NgModule({
  declarations: [
    AppComponent,
    RegisterCompanyComponent,
    CanvasCustomComponent,
    RegisterAssociationComponent,
    RegisterSeniorComponent,
    HomeComponent,
    PaymentComponent,
    PriceTableComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule.forRoot(routes),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {
}
