export type LegalForm = { name: string, value: null|string };

export const legalForms: LegalForm[] = [
  {value: 'Association de loi 1901', name:'Association de loi 1901'},
  {value: 'Entreprise individuelle (EI)', name:'Entreprise individuelle (EI)'},
  {value: 'Entreprise individuelle à responsabilité limitée (EIRL)', name:'Entreprise individuelle à responsabilité limitée (EIRL)'},
  {value: 'Entreprise unipersonnelle à responsabilité limitée (EURL)', name:'Entreprise unipersonnelle à responsabilité limitée (EURL)'},
  {value: 'Fondation', name:'Fondation'},
  {value: 'Groupement d’intérêt économique (GIE', name:'Groupement d’intérêt économique (GIE'},
  {value: 'Société anonyme (SA)', name:'Société anonyme (SA)'},
  {value: 'Société à responsabilité limitée Unipersonnelle', name:'Société à responsabilité limitée Unipersonnelle'},
  {value: 'Société à responsabilité limitée', name:'Société à responsabilité limitée'},
  {value: 'Société civile', name:'Société civile'},
  {value: 'Société civile immobilière (SCI)', name:'Société civile immobilière (SCI)'},
  {value: 'Société civile professionnelle', name:'Société civile professionnelle'},
  {value: 'Société d’exercice libéral à responsabilité limitée (SELARL)', name:'Société d’exercice libéral à responsabilité limitée (SELARL)'},
  {value: 'Société en commandite par actions (SCA)', name:'Société en commandite par actions (SCA)'},
  {value: 'Société en commandite simple (SCS)', name:'Société en commandite simple (SCS)'},
  {value: 'Société en nom collectif (SNC)', name:'Société en nom collectif (SNC)'},
  {value: 'Société par actions simplifiée (SAS)', name:'Société par actions simplifiée (SAS)'},
  {value: 'Société par actions simplifiée unipersonnelle (SASU)', name:'Société par actions simplifiée unipersonnelle (SASU)'},
  {value: 'Syndicat professionnel', name:'Syndicat professionnel'},
  {value: null, name:'Autre: Préciser'},
];
