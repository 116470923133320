<div class="container mw-100">
  <div class="wrapper card border-0 pb-5">
    <h1 class="text-center my-3">
      Bulletin d'Adhésion Entreprise en ligne
    </h1>
    <div class="header">
      <ul>
        <li class="active form_1_progressbar" #form_1_progressbar>
          <div>
            <p>1</p>
          </div>
        </li>
        <li class="form_2_progressbar" #form_2_progressbar>
          <div>
            <p>2</p>
          </div>
        </li>
        <li class="form_3_progressbar" #form_3_progressbar>
          <div>
            <p>3</p>
          </div>
        </li>
      </ul>
    </div>
    <div class="form_wrap">
      <div class="form_1 data_info" #form_1>
        <h2>Nos Informations</h2>
        <form
          (ngSubmit)="saveData()"
          [formGroup]="registerCompagnyInformationForm"
        >
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              formControlName="subscription"
              type="radio"
              value="newSubscribtion"
              id="flexRadioDefault1"
            />
            <label class="form-check-label" for="flexRadioDefault1">
              Nouvel adhérent
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              formControlName="subscription"
              type="radio"
              value="renewal"
              id="flexRadioDefault2"
            />
            <label class="form-check-label" for="flexRadioDefault2">
              Renouvellement
            </label>
          </div>
          <div class="row my-3">
            <div class="col-md-6 col-sm-12 mb-3">
              <div class="form-group">
                <label for="lastName" class="required">Nom</label>
                <input
                  type="text"
                  formControlName="lastName"
                  class="form-control"
                  id="lastName"
                  aria-describedby="lastName Help"
                  placeholder="Entrez votre nom"
                  [ngClass]="{
                    'is-invalid':
                      registerCompagnyInformationForm.get('lastName')
                        ?.invalid &&
                      (registerCompagnyInformationForm.get('lastName')?.dirty ||
                        registerCompagnyInformationForm.get('lastName')
                          ?.touched)
                  }"
                />
                <div class="invalid-feedback">
                  <span
                    *ngIf="
                      registerCompagnyInformationForm.get('lastName')
                        ?.invalid &&
                      (registerCompagnyInformationForm.get('lastName')?.dirty ||
                        registerCompagnyInformationForm.get('lastName')
                          ?.touched)
                    "
                  >
                    Le nom est requis
                  </span>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label for="firstName" class="required">Prénom</label>
                <input
                  formControlName="firstName"
                  type="text"
                  class="form-control"
                  id="firstName"
                  aria-describedby="firstName Help"
                  placeholder="Entrez votre prénom"
                  [ngClass]="{
                    'is-invalid':
                      registerCompagnyInformationForm.get('firstName')
                        ?.invalid &&
                      (registerCompagnyInformationForm.get('firstName')
                        ?.dirty ||
                        registerCompagnyInformationForm.get('firstName')
                          ?.touched)
                  }"
                />
                <div class="invalid-feedback">
                  <span
                    *ngIf="
                      registerCompagnyInformationForm.get('firstName')
                        ?.invalid &&
                      (registerCompagnyInformationForm.get('firstName')
                        ?.dirty ||
                        registerCompagnyInformationForm.get('firstName')
                          ?.touched)
                    "
                  >
                    Le prénom est requis
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="row my-3">
            <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label for="inputBirthDate" class="required">Date de naissance</label>
                <input
                  type="date"
                  formControlName="birthdate"
                  class="form-control"
                  id="inputBirthDate"
                  aria-describedby="birthDate Help"
                  placeholder="Entrez votre date de naissance"
                  [ngClass]="{
                    'is-invalid':
                      registerCompagnyInformationForm.get('birthdate')
                        ?.invalid &&
                      (registerCompagnyInformationForm.get('birthdate')
                        ?.dirty ||
                        registerCompagnyInformationForm.get('birthdate')
                          ?.touched)
                  }"
                />
                <div class="invalid-feedback">
                  <span
                    *ngIf="
                      registerCompagnyInformationForm.get('birthdate')
                        ?.invalid &&
                      (registerCompagnyInformationForm.get('birthdate')
                        ?.dirty ||
                        registerCompagnyInformationForm.get('birthdate')
                          ?.touched)
                    "
                  >
                    La date est requise
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="my-3">
            <div class="alert alert-light">
              Je soussigné(e), {{ getFullName() }}, né(e) le {{ getBirthDate() }}, en ma qualité de représentant(e)
              légal(e), déclare que l’entreprise suivante adhère à l’UDE-MEDEF Guadeloupe :
            </div>
          </div>
          <div class="row my-3">
            <div class="col-md-6 col-sm-12 mb-3">
              <div class="form-group">
                <label for="corporateName" class="required">Raison sociale</label>
                <input
                  type="text"
                  class="form-control"
                  id="corporateName"
                  formControlName="companyName"
                  [ngClass]="{
                    'is-invalid':
                      registerCompagnyInformationForm.get('companyName')
                        ?.invalid &&
                      (registerCompagnyInformationForm.get('companyName')
                        ?.dirty ||
                        registerCompagnyInformationForm.get('companyName')
                          ?.touched)
                  }"
                  aria-describedby="corporateName Help"
                  placeholder="Entrez la raison sociale"
                />
                <div class="invalid-feedback">
                  <span
                    *ngIf="
                      registerCompagnyInformationForm.get('companyName')
                        ?.invalid &&
                      (registerCompagnyInformationForm.get('companyName')
                        ?.dirty ||
                        registerCompagnyInformationForm.get('companyName')
                          ?.touched)
                    "
                  >
                    La raison sociale est requise
                  </span>
                </div>
              </div>
            </div>

            <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label for="tradeName" class="required">Nom commercial</label>
                <input
                  type="text"
                  class="form-control"
                  id="tradeName"
                  formControlName="tradeName"
                  [ngClass]="{
                    'is-invalid':
                      registerCompagnyInformationForm.get('tradeName')
                        ?.invalid &&
                      (registerCompagnyInformationForm.get('tradeName')
                        ?.dirty ||
                        registerCompagnyInformationForm.get('tradeName')
                          ?.touched)
                  }"
                  aria-describedby="tradeName Help"
                  placeholder="Entrez le nom commercial"
                />
                <div class="invalid-feedback">
                  <span
                    *ngIf="
                      registerCompagnyInformationForm.get('tradeName')
                        ?.invalid &&
                      (registerCompagnyInformationForm.get('tradeName')
                        ?.dirty ||
                        registerCompagnyInformationForm.get('tradeName')
                          ?.touched)
                    "
                  >
                    Le nom commerciale est requis
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="row my-3">
            <div class="col-sm-12">
              <div class="form-group">
                <label for="address" class="required">Adresse</label>
                <input
                  type="text"
                  [ngClass]="{
                    'is-invalid':
                      registerCompagnyInformationForm.get('address')?.invalid &&
                      (registerCompagnyInformationForm.get('address')?.dirty ||
                        registerCompagnyInformationForm.get('address')?.touched)
                  }"
                  class="form-control"
                  id="address"
                  formControlName="address"
                  aria-describedby="address Help"
                  placeholder="Entrez l'adresse"
                />
                <div class="invalid-feedback">
                  <span
                    *ngIf="
                      registerCompagnyInformationForm.get('address')?.invalid &&
                      (registerCompagnyInformationForm.get('address')?.dirty ||
                        registerCompagnyInformationForm.get('address')?.touched)
                    "
                  >
                    L'adresse est requise
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="row my-3">
            <div class="col-sm-12">
              <div class="form-group">
                <label>Complément d'adresse</label>
                <input type="text" class="form-control" placeholder="Entrez un complément d'adresse"
                       formControlName="addressComplement"
                />
              </div>
            </div>
          </div>
          <div class="row my-3">
            <div class="col-md-6 col-sm-12 mb-3">
              <div class="form-group">
                <label class="required" for="postalCode">Code Postal</label>
                <input
                  type="text"
                  class="form-control"
                  id="postalCode"
                  formControlName="postalCode"
                  aria-describedby="postalCode Help"
                  placeholder="Entrez le code postal"
                  [ngClass]="{
                    'is-invalid':
                      registerCompagnyInformationForm.get('postalCode')
                        ?.invalid &&
                      (registerCompagnyInformationForm.get('postalCode')
                        ?.dirty ||
                        registerCompagnyInformationForm.get('postalCode')
                          ?.touched)
                  }"
                />
                <div class="invalid-feedback">
                  <span
                    *ngIf="
                      registerCompagnyInformationForm.get('postalCode')
                        ?.invalid &&
                      (registerCompagnyInformationForm.get('postalCode')
                        ?.dirty ||
                        registerCompagnyInformationForm.get('postalCode')
                          ?.touched)
                    "
                  >
                    Le code postal est requis
                  </span>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label class="required" for="city">Ville</label>
                <input
                  type="text"
                  class="form-control"
                  id="city"
                  formControlName="city"
                  aria-describedby="city Help"
                  placeholder="Entrez la ville"
                  [ngClass]="{
                    'is-invalid':
                      registerCompagnyInformationForm.get('city')?.invalid &&
                      (registerCompagnyInformationForm.get('city')?.dirty ||
                        registerCompagnyInformationForm.get('city')?.touched)
                  }"
                />
                <div class="invalid-feedback">
                  <span
                    *ngIf="
                      registerCompagnyInformationForm.get('city')?.invalid &&
                      (registerCompagnyInformationForm.get('city')?.dirty ||
                        registerCompagnyInformationForm.get('city')?.touched)
                    "
                  >
                    La ville est requise
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="row my-3">
            <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label class="required" for="job">Fonction</label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="job"
                  id="job"
                  aria-describedby="job Help"
                  placeholder="Entrez la fonction"
                  [ngClass]="{
                    'is-invalid':
                      registerCompagnyInformationForm.get('job')?.invalid &&
                      (registerCompagnyInformationForm.get('job')?.dirty ||
                        registerCompagnyInformationForm.get('job')?.touched)
                  }"
                />
                <div class="invalid-feedback">
                  <span
                    *ngIf="
                      registerCompagnyInformationForm.get('job')?.invalid &&
                      (registerCompagnyInformationForm.get('job')?.dirty ||
                        registerCompagnyInformationForm.get('job')?.touched)
                    "
                  >
                    La fonction est requise
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="row my-3">
            <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label class="required" for="siretNumber">Numéro Siret</label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="siretNumber"
                  id="siretNumber"
                  aria-describedby="siretNumber Help"
                  placeholder="Entrez le numéro siret"
                  [ngClass]="{
                    'is-invalid':
                      registerCompagnyInformationForm.get('siretNumber')
                        ?.invalid &&
                      (registerCompagnyInformationForm.get('siretNumber')
                        ?.dirty ||
                        registerCompagnyInformationForm.get('siretNumber')
                          ?.touched)
                  }"
                />
                <div class="invalid-feedback">
                  <span
                    *ngIf="
                      registerCompagnyInformationForm.get('siretNumber')
                        ?.invalid &&
                      (registerCompagnyInformationForm.get('siretNumber')
                        ?.dirty ||
                        registerCompagnyInformationForm.get('siretNumber')
                          ?.touched ||
                        registerCompagnyInformationForm
                          .get('siretNumber')
                          ?.hasError('errorSiretNumber'))
                    "
                  >
                    La numéro de siret est requis
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="row my-3">
            <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label for="apeCode">Code APE</label>
                <input
                  type="text"
                  formControlName="apeCode"
                  class="form-control"
                  id="apeCode"
                  aria-describedby="apeCode Help"
                  placeholder="Entrez le code APE"
                />
              </div>
            </div>
          </div>
          <div class="row my-3">
            <div class="col-sm-12">
              <div class="form-group">
                <label class="required" for="professionalActivity"
                >Secteur d'activité professionnelle</label
                >
                <select
                  class="form-control"
                  name="professionalActivity"
                  id="professionalActivity"
                  formControlName="professionalActivity"
                  [ngClass]="{
                    'is-invalid':
                      registerCompagnyInformationForm.get(
                        'professionalActivity'
                      )?.value === null &&
                      (registerCompagnyInformationForm.get(
                        'professionalActivity'
                      )?.dirty ||
                        registerCompagnyInformationForm.get(
                          'professionalActivity'
                        )?.touched)
                  }"
                >
                  <option [ngValue]="null" disabled>- Sélectionner -</option>
                  <option *ngFor="let ape of listeApes" [ngValue]="ape.code">
                    {{ ape.code }} - {{ ape.label }}
                  </option>
                </select>
                <div class="invalid-feedback">
                  <span
                    *ngIf="
                      registerCompagnyInformationForm.get(
                        'professionalActivity'
                      )?.invalid &&
                      (registerCompagnyInformationForm.get(
                        'professionalActivity'
                      )?.dirty ||
                        registerCompagnyInformationForm.get(
                          'professionalActivity'
                        )?.touched)
                    "
                  >
                    Le secteur d'activité est requis
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="row my-3">
            <div class="col-sm-12">
              <div class="form-group">
                <label class="required" for="legalForm">Forme juridique</label>
                <select class="form-control" name="legalForm" id="legalForm" formControlName="legalForm"
                        [ngClass]="{'is-invalid': registerCompagnyInformationForm.get('legalForm')?.value === null &&
                         (registerCompagnyInformationForm.get('legalForm')?.dirty
                         || registerCompagnyInformationForm.get('legalForm')?.touched)}">
                  <option [value]="null" disabled>- Sélectionner -</option>
                  <option *ngFor="let lf of legalForms" [value]="lf.value">
                    {{ lf.name }}
                  </option>
                </select>
                <div class="invalid-feedback">
                  <span
                    *ngIf="
                      registerCompagnyInformationForm.get('legalForm')
                        ?.invalid &&
                      (registerCompagnyInformationForm.get('legalForm')
                        ?.dirty ||
                        registerCompagnyInformationForm.get('legalForm')
                          ?.touched)
                    "
                  >
                    La forme juridique est requise
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="row my-3">
            <div class="col-md-6 col-sm-12 mb-3">
              <div class="form-group">
                <label class="required" for="mobilePhone">Téléphone mobile</label>
                <input
                  type="tel"
                  class="form-control"
                  id="mobilePhone"
                  [ngClass]="{
                    'is-invalid':
                      registerCompagnyInformationForm.get('mobilePhone')
                        ?.invalid &&
                      (registerCompagnyInformationForm.get('mobilePhone')
                        ?.dirty ||
                        registerCompagnyInformationForm.get('mobilePhone')
                          ?.touched)
                  }"
                  formControlName="mobilePhone"
                  aria-describedby="mobilePhone Help"
                  placeholder="Entrez votre téléphone mobile"
                />
                <div class="invalid-feedback">
                  <span
                    *ngIf="
                      registerCompagnyInformationForm.get('mobilePhone')
                        ?.invalid &&
                      (registerCompagnyInformationForm.get('mobilePhone')
                        ?.dirty ||
                        registerCompagnyInformationForm.get('mobilePhone')
                          ?.touched)
                    "
                  >
                    Le numéro de téléphone mobile est requis
                  </span>
                </div>
              </div>
            </div>

            <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label for="fixePhone">Téléphone fixe</label>
                <input
                  type="tel"
                  [ngClass]="{
                    'is-invalid':
                      registerCompagnyInformationForm.get('fixePhone')
                        ?.invalid &&
                      (registerCompagnyInformationForm.get('fixePhone')
                        ?.dirty ||
                        registerCompagnyInformationForm.get('fixePhone')
                          ?.touched)
                  }"
                  formControlName="fixePhone"
                  class="form-control"
                  id="fixePhone"
                  aria-describedby="fixePhone Help"
                  placeholder="Entrez votre téléphone fixe"
                />
                <div class="invalid-feedback">
                  <span
                    *ngIf="
                      registerCompagnyInformationForm.get('fixePhone')
                        ?.invalid &&
                      (registerCompagnyInformationForm.get('fixePhone')
                        ?.dirty ||
                        registerCompagnyInformationForm.get('fixePhone')
                          ?.touched)
                    "
                  >
                    Le numéro de téléphone fixe est requis
                  </span>
                </div>
              </div>
            </div>


          </div>
          <div class="row my-3">
            <div class="col-sm-12">
              <div class="form-group">
                <label class="required" for="email">Email</label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="email"
                  [ngClass]="{
                    'is-invalid':
                      registerCompagnyInformationForm.get('email')?.invalid &&
                      (registerCompagnyInformationForm.get('email')?.dirty ||
                        registerCompagnyInformationForm.get('email')?.touched)
                  }"
                  id="email"
                  aria-describedby="email Help"
                  placeholder="Entrez votre email"
                />
                <div class="invalid-feedback">
                  <div
                    *ngIf="
                      registerCompagnyInformationForm.get('email')?.invalid &&
                      (registerCompagnyInformationForm.get('email')?.dirty ||
                        registerCompagnyInformationForm.get('email')?.touched)
                    "
                  >
                    L'email est requis
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group my-3">
            <p class="required">Travailleur indépendant</p>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                (click)="handleSelfEmployed('selfEmployed')"
                id="selfEmployed1"
                formControlName="isSelfEmployed"
                value="selfEmployed"
              />
              <label class="form-check-label" for="selfEmployed1"> Oui </label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                (click)="handleSelfEmployed('none')"
                id="selfEmployed2"
                formControlName="isSelfEmployed"
                value="none"
              />
              <label class="form-check-label" for="selfEmployed2"> Non </label>
            </div>
          </div>
          <div *ngIf="showNumberUrsaf" class="row my-3">
            <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label for="numberURSAFF">Si oui, n°URSAFF TI</label>
                <input
                  type="text"
                  class="form-control"
                  id="numberURSAFF"
                  formControlName="numberURSAFF"
                  [ngClass]="{
                    'is-invalid':
                      registerCompagnyInformationForm.get('numberURSAFF')
                        ?.invalid &&
                      (registerCompagnyInformationForm.get('numberURSAFF')
                        ?.dirty ||
                        registerCompagnyInformationForm.get('numberURSAFF')
                          ?.touched)
                  }"
                  aria-describedby="numberURSAFF Help"
                  placeholder="Entrez votre numéro URSAFF"
                />
                <div class="invalid-feedback">
                  <span
                    *ngIf="
                      registerCompagnyInformationForm.get('numberURSAFF')
                        ?.invalid &&
                      (registerCompagnyInformationForm.get('numberURSAFF')
                        ?.dirty ||
                        registerCompagnyInformationForm.get('numberURSAFF')
                          ?.touched)
                    "
                  >
                    Le numéro URSAFF n'est pas valide
                  </span>
                </div>
              </div>
            </div>
          </div>
          <h3 class="text-center my-3">Communication</h3>
          <hr/>
          <div class="my-3">
            <p>Recevoir les communications de l'UDE par mail:</p>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                formControlName="receiveCommunicationsByEMail"
                id="receiveCommunicationsByEMail1"
                value="email"
              />
              <label
                class="form-check-label"
                for="receiveCommunicationsByEMail1"
              >
                Oui
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                formControlName="receiveCommunicationsByEMail"
                id="receiveCommunicationsByEMail2"
                value="none"
              />
              <label
                class="form-check-label"
                for="receiveCommunicationsByEMail2"
              >
                Non
              </label>
            </div>
          </div>
          <div class="my-3">
            <p>Recevoir les communications de l'UDE par WhatsApp:</p>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                formControlName="receiveCommunicationsByWhatsApp"
                id="ReceiveCommunicationsByWhatsApp1"
                value="whatsApp"
              />
              <label
                class="form-check-label"
                for="ReceiveCommunicationsByWhatsApp1"
              >
                Oui
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                formControlName="receiveCommunicationsByWhatsApp"
                id="ReceiveCommunicationsByWhatsApp2"
                value="none"
              />
              <label
                class="form-check-label"
                for="ReceiveCommunicationsByWhatsApp2"
              >
                Non
              </label>
            </div>
          </div>
        </form>
      </div>
      <div class="form_2 data_info" style="display: none" #form_2>
        <h2>Nos Interlocuteurs</h2>
        <div class="ourContacts__container">
          <div *ngFor="let contact of contacts" class="contact">
            <div *ngIf="contact">
              <div>
                Nom/Prénom: {{ contact?.lastName }} {{ contact?.firstName }}
              </div>
              <div>Fonction: {{ contact?.job }}</div>
              <div>Téléphone: {{ contact?.phone }}</div>
            </div>
            <div class="masqued">
              <button
                class="btn btn-primary btn-sm"
                (click)="openModal(contact.id, 'edit')"
              >
                <span class="material-symbols-outlined">edit</span>
              </button>
              <button
                class="btn btn-primary btn-sm"
                (click)="openModal(contact.id, 'duplicate')"
              >
                <span class="material-symbols-outlined"> content_copy </span>
              </button>
              <button
                class="btn btn-primary btn-sm"
                (click)="deleteContact(contact.id)"
              >
                <span class="material-symbols-outlined"> delete </span>
              </button>
            </div>
          </div>
          <div class="contact add card">
            <button (click)="openModal(null, 'create')" class="btn btn-primary">
              + Ajouter un interlocuteur
            </button>
          </div>
        </div>
        <h3 class="my-3">Droit à l'image</h3>
        <hr/>
        <form [formGroup]="registerCompagnyAuthorizeMyImageForm">
          <div class="form-check my-3">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckChecked"
              formControlName="authorizeMyImage"
            />
            <label class="form-check-label" for="flexCheckChecked">
              J'autorise l’UDE à capter et à exploiter à titre gracieux mon
              image sur tous les supports de diffusion de l’UDE-MEDEF (livrets,
              réseaux sociaux, e-mailing, site internet…) et en tout formats,
              dans la limite de la durée maximale autorisée par la CNIL.
            </label>
          </div>
        </form>
      </div>
      <div class="form_3 data_info" style="display: none" #form_3>
        <h2>Cotisations Entreprise</h2>
        <div class="picture">

          <app-price-table></app-price-table>
        </div>
        <hr/>
        <form [formGroup]="registerCompagnyCotisationForm">
          <div class="row my-3">
            <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <div class="form-group">
                  <label for="headcount"
                  >Effectif (nombre exact de salariés)</label
                  >
                  <input
                    (input)="calculateFees($event)"
                    type="number"
                    min="0"
                    class="form-control"
                    id="headcount"
                    formControlName="headcount"
                    aria-describedby="headcount Help"
                    placeholder="Entrez l'effectif"
                    [ngClass]="{
                      'is-invalid':
                        registerCompagnyCotisationForm.get('headcount')
                          ?.invalid &&
                        (registerCompagnyCotisationForm.get('headcount')
                          ?.dirty ||
                          registerCompagnyCotisationForm.get('headcount')
                            ?.touched)
                    }"
                  />
                  <div class="invalid-feedback">
                    <span
                      *ngIf="
                        registerCompagnyCotisationForm.get('headcount')
                          ?.invalid &&
                        (registerCompagnyCotisationForm.get('headcount')
                          ?.dirty ||
                          registerCompagnyCotisationForm.get('headcount')
                            ?.touched)
                      "
                    >
                      L'effectif est requis
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row my-3">
            <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label>Montant de la cotisation (€)</label>
                <input type="text" class="form-control" [disabled]="true" [value]="fees + ' €'"/>
              </div>
            </div>
          </div>

          <div class="row my-3">
            <div class="col-md-8 col-sm-12">
              <label>Modalités de règlement</label>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  formControlName="terme"
                  value="onlineCreditCard"
                  id="flexRadioTermt1"
                  (click)="handleSetTerme('onlineCreditCard')"
                />
                <label class="form-check-label" for="flexRadioTermt1">
                  Carte bleue en ligne
                </label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  formControlName="terme"
                  value="onsiteCreditCard"
                  id="flexRadioTermt2"
                  (click)="handleSetTerme('onsiteCreditCard')"
                />
                <label class="form-check-label" for="flexRadioTermt2">
                  Carte bleue (sur place ou par téléphone au 0590 26 83 58)
                </label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  formControlName="terme"
                  value="chequePayable"
                  id="flexRadioTermt3"
                  (click)="handleSetTerme('chequePayable')"
                />
                <label class="form-check-label" for="flexRadioTermt3">
                  Chèque à l'ordre de l'Union Des Entreprises - Medef Guadeloupe
                </label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  formControlName="terme"
                  value="bankTransfer"
                  id="flexRadioTermt4"
                  (click)="handleSetTerme('bankTransfer')"
                />
                <label class="form-check-label" for="flexRadioTermt4">
                  Virement - libellé "NOM DE VOTRE ENTREPRISE" - COTISATION UDE
                  MEDEF {{year}}
                </label>
              </div>
            </div>

            <div *ngIf="showPartOnlineCreditCard">
              <div class="my-3">
                <i>Possibilité de paiement en plusieurs fois</i>

                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    (click)="choicePaymentInInstalments($event)"
                    id="checkBoxPaymentInstalments"
                    formControlName="paymentInInstalments"
                  />
                  <label
                    class="form-check-label"
                    for="checkBoxPaymentInstalments"
                  >
                    Je souhaite payer en plusieurs fois
                  </label>
                </div>
                <div *ngIf="showNbrInstalments" class="my-3">
                  <i>Paiement en plusieurs fois</i>
                  <div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        formControlName="nbrInstalments"
                        type="radio"
                        value="2"
                        id="instalments1"
                      />
                      <label class="form-check-label" for="instalments1">
                        2 fois sans frais
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        formControlName="nbrInstalments"
                        type="radio"
                        value="3"
                        id="instalments2"
                      />
                      <label class="form-check-label" for="instalments2">
                        3 fois sans frais
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="showPartOnsiteCreditCard"></div>
            <div *ngIf="showPartChequePayable">
              <div class="row my-3">
                <div class="col-md-8 col-sm-12">
                  <div class="form-group">
                    <label for="checkNumber">N° de chèque</label>
                    <input
                      type="number"
                      [ngClass]="{
                        'is-invalid':
                          registerCompagnyCotisationForm.get('checkNumber')
                            ?.invalid &&
                          (registerCompagnyCotisationForm.get('checkNumber')
                            ?.dirty ||
                            registerCompagnyCotisationForm.get('checkNumber')
                              ?.touched)
                      }"
                      formControlName="checkNumber"
                      class="form-control"
                      id="checkNumber"
                      aria-describedby="checkNumber Help"
                      placeholder="Entrez votre numéro de chèque"
                    />
                    <div class="invalid-feedback">
                      <span
                        *ngIf="
                          registerCompagnyCotisationForm.get('checkNumber')
                            ?.invalid &&
                          (registerCompagnyCotisationForm.get('checkNumber')
                            ?.dirty ||
                            registerCompagnyCotisationForm.get('checkNumber')
                              ?.touched)
                        "
                      >
                        Le numéro du chèque est requis
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="showPartBankTransfer">
              <div class="row my-3">
                <div class="col-md-8 col-sm-12">
                  <div class="form-group">
                    <label for="transferNumber">N° de virement</label>
                    <input
                      type="number"
                      [ngClass]="{
                        'is-invalid':
                          registerCompagnyCotisationForm.get('transferNumber')
                            ?.invalid &&
                          (registerCompagnyCotisationForm.get('transferNumber')
                            ?.dirty ||
                            registerCompagnyCotisationForm.get('transferNumber')
                              ?.touched)
                      }"
                      formControlName="transferNumber"
                      class="form-control"
                      id="transferNumber"
                      aria-describedby="transferNumber Help"
                      placeholder="Entrez le numéro de virement"
                    />
                    <i
                    >IBAN : FR76 1010 7004 7300 4350 4665 814 / Code BIC:
                      BREDFRPPXXXIBAN : FR76 1010 7004 7300 4350 4665 814 / Code
                      BIC: BREDFRPPXXX</i
                    >
                    <div class="invalid-feedback">
                      <span
                        *ngIf="
                          registerCompagnyCotisationForm.get('transferNumber')
                            ?.invalid &&
                          (registerCompagnyCotisationForm.get('transferNumber')
                            ?.dirty ||
                            registerCompagnyCotisationForm.get('transferNumber')
                              ?.touched)
                        "
                      >
                        Le numéro du virement est requis
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <h5 class="my-3 text-center lead">
              L'ADHÉSION À L'UDE-MEDEF VAUT ÉGALEMENT ADHÉSION PLEINE ET ENTIÈRE
              À LA CHARTE DE L'ORGANISATION
            </h5>
            <hr/>
            <div class="row my-3">
              <div class="col-md-8 col-sm-12">
                <div class="form-group">
                  <label for="location">Fait à</label>
                  <input
                    type="text"
                    class="form-control"
                    id="location"
                    [ngClass]="{
                      'is-invalid':
                        registerCompagnyCotisationForm.get('location')
                          ?.invalid &&
                        (registerCompagnyCotisationForm.get('location')
                          ?.dirty ||
                          registerCompagnyCotisationForm.get('location')
                            ?.touched)
                    }"
                    formControlName="location"
                    aria-describedby="location Help"
                    placeholder="Entrez le lieu"
                  />
                  <div class="invalid-feedback">
                    <span
                      *ngIf="
                        registerCompagnyCotisationForm.get('location')
                          ?.invalid &&
                        (registerCompagnyCotisationForm.get('location')
                          ?.dirty ||
                          registerCompagnyCotisationForm.get('location')
                            ?.touched)
                      "
                    >
                      Le lieu est requis
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row my-3">
              <div class="col-md-8 col-sm-12">
                <div class="form-group">
                  <label for="datePayment">Le</label>
                  <input
                    type="date"
                    class="form-control"
                    id="datePayment"
                    [ngClass]="{
                      'is-invalid':
                        registerCompagnyCotisationForm.get('datePayment')
                          ?.invalid &&
                        (registerCompagnyCotisationForm.get('datePayment')
                          ?.dirty ||
                          registerCompagnyCotisationForm.get('datePayment')
                            ?.touched)
                    }"
                    formControlName="datePayment"
                    aria-describedby="datePayment Help"
                    placeholder="Entrez la date de paiement"
                  />
                  <div class="invalid-feedback">
                    <span
                      *ngIf="
                        registerCompagnyCotisationForm.get('datePayment')
                          ?.invalid &&
                        (registerCompagnyCotisationForm.get('datePayment')
                          ?.dirty ||
                          registerCompagnyCotisationForm.get('datePayment')
                            ?.touched)
                      "
                    >
                      La date de paiement est requise
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row my-3">
              <div class="col-md-8 col-sm-12">
                <label for="">SIGNATURE & CACHET ENTREPRISE</label>
                <div
                  [ngClass]="{
                    'is-invalid':
                      registerCompagnyCotisationForm.get('signature')
                        ?.invalid &&
                      (registerCompagnyCotisationForm.get('signature')
                        ?.touched ||
                        registerCompagnyCotisationForm.get('signature')?.dirty)
                  }"
                >
                  <app-canvas-custom
                    formControlName="signature"
                    [method]="action"
                    (exportImage)="handleExportImage($event)"
                    #signatureCanvas
                  ></app-canvas-custom>
                </div>

                <div class="invalid-feedback">
                  <span
                    *ngIf="
                      registerCompagnyCotisationForm.get('signature')
                        ?.invalid &&
                      (registerCompagnyCotisationForm.get('signature')
                        ?.touched ||
                        registerCompagnyCotisationForm.get('signature')?.dirty)
                    "
                  >
                    La signature est requise
                  </span>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="btns_wrap">
      <div class="common_btns form_1_btns" #form_1_btns>
        <button (click)="handleFill()" type="button" class="btn btn-primary d-none">remplir</button>
        <button
          (click)="handleBtn1Next()"
          [disabled]="registerCompagnyInformationForm.invalid"
          type="button"
          class="btn btn-primary btn_next"
        >
          Suivant
          <span class="icon">
            <i class="fa-solid fa-arrow-right"></i>
          </span>
        </button>
      </div>
      <div class="common_btns form_2_btns" #form_2_btns style="display: none">
        <button
          (click)="handleBtn2Back()"
          type="button"
          class="btn btn-primary btn_back"
        >
          <span class="icon">
            <i class="fa-solid fa-arrow-left"></i>
          </span>
          Précedent
        </button>
        <button
          (click)="handleBtn2Next()"
          type="button"
          class="btn btn-primary btn_next"
        >
          Suivant
          <span class="icon">
            <i class="fa-solid fa-arrow-right"></i>
          </span>
        </button>
      </div>
      <div class="common_btns form_3_btns" #form_3_btns style="display: none">
        <button
          (click)="handleBtn3Back()"
          type="button"
          class="btn btn-primary btn_back"
        >
          <span class="icon">
            <i class="fa-solid fa-arrow-left"></i>
          </span>
          Précedent
        </button>
        <button (click)="send()" type="button" class="btn btn-primary btn_end"
                [disabled]="!registerCompagnyCotisationForm.valid">
          Terminer
          <span class="icon"></span>
        </button>
      </div>
    </div>
  </div>
</div>
<ng-template #modal let-modal style="height: 600px">
  <div class="modal-header border-0 pb-0">
    <h4 class="modal-title text-uppercase font-poppins text-sp-orange">
      Contact
    </h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    ></button>
  </div>
  <div class="modal-body">
    <form [formGroup]="registerCompagnyOurContactsForm">
      <div class="row my-3">
        <div class="col-md-6 col-sm-12">
          <div class="form-group">
            <label for="lastName">Nom</label>
            <input
              type="text"
              formControlName="lastName"
              class="form-control"
              id="lastName"
              aria-describedby="lastName Help"
              placeholder="Entrez votre nom"
              [ngClass]="{
                'is-invalid':
                  registerCompagnyOurContactsForm.get('lastName')?.invalid &&
                  (registerCompagnyOurContactsForm.get('lastName')?.dirty ||
                    registerCompagnyOurContactsForm.get('lastName')?.touched)
              }"
            />
            <div class="invalid-feedback">
              <span
                *ngIf="
                  registerCompagnyOurContactsForm.get('lastName')?.invalid &&
                  (registerCompagnyOurContactsForm.get('lastName')?.dirty ||
                    registerCompagnyOurContactsForm.get('lastName')?.touched)
                "
              >
                Le nom est requis
              </span>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-12">
          <div class="form-group">
            <label for="firstName">Prénom</label>
            <input
              formControlName="firstName"
              type="text"
              class="form-control"
              id="firstName"
              aria-describedby="firstName Help"
              placeholder="Entrez votre prénom"
              [ngClass]="{
                'is-invalid':
                  registerCompagnyOurContactsForm.get('firstName')?.invalid &&
                  (registerCompagnyOurContactsForm.get('firstName')?.dirty ||
                    registerCompagnyOurContactsForm.get('firstName')?.touched)
              }"
            />
            <div class="invalid-feedback">
              <span
                *ngIf="
                  registerCompagnyOurContactsForm.get('firstName')?.invalid &&
                  (registerCompagnyOurContactsForm.get('firstName')?.dirty ||
                    registerCompagnyOurContactsForm.get('firstName')?.touched)
                "
              >
                Le prénom est requis
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="row my-3">
        <div class="col-md-6 col-sm-12">
          <div class="form-group">
            <label for="job">Fonction</label>
            <input
              type="text"
              class="form-control"
              formControlName="job"
              id="job"
              aria-describedby="job Help"
              placeholder="Entrez la fonction"
              [ngClass]="{
                'is-invalid':
                  registerCompagnyOurContactsForm.get('job')?.invalid &&
                  (registerCompagnyOurContactsForm.get('job')?.dirty ||
                    registerCompagnyOurContactsForm.get('job')?.touched)
              }"
            />
            <div class="invalid-feedback">
              <span
                *ngIf="
                  registerCompagnyOurContactsForm.get('job')?.invalid &&
                  (registerCompagnyOurContactsForm.get('job')?.dirty ||
                    registerCompagnyOurContactsForm.get('job')?.touched)
                "
              >
                La fonction est requise
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="row my-3">
        <div class="col-md-6 col-sm-12">
          <div class="form-group">
            <label for="fixePhone">Téléphone</label>
            <input
              type="tel"
              [ngClass]="{
                'is-invalid':
                  registerCompagnyOurContactsForm.get('phone')?.invalid &&
                  (registerCompagnyOurContactsForm.get('phone')?.dirty ||
                    registerCompagnyOurContactsForm.get('phone')?.touched)
              }"
              formControlName="phone"
              class="form-control"
              id="phone"
              aria-describedby="phone Help"
              placeholder="Entrez votre téléphone"
            />
            <div class="invalid-feedback">
              <span
                *ngIf="
                  registerCompagnyOurContactsForm.get('phone')?.invalid &&
                  (registerCompagnyOurContactsForm.get('phone')?.dirty ||
                    registerCompagnyOurContactsForm.get('phone')?.touched)
                "
              >
                Le téléphone est requis
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="my-3">
        <p>Recevoir les communications de l'UDE par mail:</p>
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            formControlName="receiveCommunicationsByEMail_ocf"
            id="receiveCommunicationsByEMail_ocf1"
            value="email"
          />
          <label
            class="form-check-label"
            for="receiveCommunicationsByEMail_ocf1"
          >
            Oui
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input"
                 formControlName="receiveCommunicationsByEMail_ocf"
                 id="receiveCommunicationsByEMail_ocf2"
                 type="radio"
                 value="none"/>
          <label class="form-check-label"
                 for="receiveCommunicationsByEMail_ocf2">
            Non
          </label>
        </div>
      </div>
      <div class="my-3">
        <p>Recevoir les communications de l'UDE par WhatsApp:</p>
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            formControlName="receiveCommunicationsByWhatsApp_ocf"
            id="ReceiveCommunicationsByWhatsApp_ocf1"
            value="whatsApp"
          />
          <label
            class="form-check-label"
            for="ReceiveCommunicationsByWhatsApp_ocf1"
          >
            Oui
          </label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            formControlName="receiveCommunicationsByWhatsApp_ocf"
            id="ReceiveCommunicationsByWhatsApp_ocf2"
            value="none"
          />
          <label
            class="form-check-label"
            for="ReceiveCommunicationsByWhatsApp_ocf2"
          >
            Non
          </label>
        </div>
      </div>
      <div class="my-3 text-center">
        <button
          *ngIf="modeViewModal == 'create'"
          class="btn btn-primary"
          [disabled]="registerCompagnyOurContactsForm.invalid"
          (click)="create()"
        >
          Créer
        </button>
        <button
          *ngIf="modeViewModal == 'edit'"
          class="btn btn-success"
          [disabled]="registerCompagnyOurContactsForm.invalid"
          (click)="edit()"
        >
          mettre à jour
        </button>
        <button
          *ngIf="modeViewModal == 'duplicate'"
          class="btn btn-success"
          [disabled]="registerCompagnyOurContactsForm.invalid"
          (click)="create()"
        >
          dupliquer
        </button>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #modalInvoice let-modal style="height: 600px">
  <div class="modal-header border-0 pb-0">
    <h4 class="modal-title font-poppins" style="color: var(--primary_medef)">
      Voulez-vous télécharger la facture ?
    </h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    ></button>
  </div>
  <div class="modal-body">
    <div class="d-flex justify-content-around">
      <button class="btn btn btn-secondary">Annuler</button>
    </div>
  </div>
</ng-template>

<div *ngIf="debug" class="container">
  <div class="row">
    <div class="col-12">
      <div>
        <h2>Nos Informations</h2>
        <pre>
                    <ul class="list-group">
                        <li class="list-group-item">
                            {{ registerCompagnyInformationForm.value | json }}
                        </li>
                    </ul>
                </pre>
      </div>
    </div>
    <div class="col-12">
      <div>
        <h2>Nos collaborateurs</h2>
        <pre>
                    <ul class="list-group">
                        <li class="list-group-item">
                            {{ registerCompagnyOurContactsForm.value | json }}
                        </li>
                    </ul>
                </pre>
        <h2>Mon Image</h2>
        <pre>
                <ul class="list-group">
                    <li class="list-group-item">
                        {{ registerCompagnyAuthorizeMyImageForm.value | json }}
                    </li>
                </ul>
            </pre>
      </div>
    </div>
    <div class="col-12">
      <h2>Cotisation</h2>
      <pre>
                <ul class="list-group">
                    <li class="list-group-item">
                        {{ registerCompagnyCotisationForm.valid | json }}
                    </li>
                    <li class="list-group-item">
                        {{ registerCompagnyCotisationForm.value | json }}
                    </li>
                </ul>
            </pre>
    </div>
  </div>
</div>


<ng-template #paymentModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Paiement de vos cotisations</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>

  <div class="modal-body">
    <p class="text-center">Procédez au paiement de vos cotisations grâce à notre partenaire de paiement</p>
    <img src="assets/images/logo_systempay.png" alt="systempay" class="d-block mx-auto">
    <div class="mb-3">
      <form action="https://paiement.systempay.fr/vads-payment/" method="POST" ngNoForm>
        <div class="row">
          <div class="d-none mb-3 col-4" *ngFor="let input of paymentInputs">
            <input type="text" [name]="input.key" value="{{input.value}}" class="form-control form-control-sm">
          </div>
        </div>
        <input type="submit" name="payer" value="Payer"
               class="btn btn-pay d-block mx-auto mt-4 px-4 text-uppercase fw-semibold"/>
      </form>
    </div>
  </div>
</ng-template>
