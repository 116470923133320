<div class="container py-5">

  <h1 class="text-center fw-semibold">Adhérer en ligne</h1>

  <p class="text-center mb-5">
    Pour adhérer en ligne à notre association, il vous suffit de remplir le bulletin d'adhésion en ligne correspondant à
    votre situation ci-dessous :
  </p>

  <div class="row">
    <div class="col-12 col-md-4 mb-4">
      <a routerLink="/company" class="btn btn-entreprise p-3 h-100 d-flex flex-column align-items-center justify-content-center">
        <div class="text-uppercase fw-semibold mb-1">Vous représentez une entreprise ?</div>
        <div>Accédez au bulletin d'adhésion Entreprise et payez en ligne</div>
      </a>
    </div>

    <div class="col-12 col-md-4 mb-4">
      <a routerLink="/association" class="btn btn-association p-3 h-100 d-flex flex-column align-items-center justify-content-center">
        <div class="text-uppercase fw-semibold mb-1">Vous représentez une association ?</div>
        <div>Accédez au bulletin d'adhésion Association et payez en ligne</div>
      </a>
    </div>

    <div class="col-12 col-md-4 mb-4">
      <a routerLink="/senior" class="btn btn-retired p-3 h-100 d-flex flex-column align-items-center justify-content-center">
        <div class="text-uppercase fw-semibold mb-1">Vous êtes retraité(e) ?</div>
        <div>Accédez au bulletin d'adhésion Senior et payez en ligne</div>
      </a>
    </div>
  </div>
</div>
