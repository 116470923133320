import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
})
export class PaymentComponent implements OnInit {
  type: 'card' | 'other' = 'other';
  status: 'annule' | 'autorise' | 'refuse' | 'erreur' = 'erreur';

  constructor(private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    const status = this.activatedRoute.snapshot.params['status'];
    if (status) {
      this.status = status;
      this.type = 'card';
    }
  }
}
