import { Component, OnInit } from '@angular/core';
import { FeeService, Price } from '../../services/fee.service';

@Component({
  selector: 'app-price-table',
  styles: [`
    table {
      font-family: 'Barlow', sans-serif;
      table-layout: fixed;
      font-size: 15px;

      tr, th, td {
        border-color: #2e3092;
        vertical-align: middle;
      }

      thead {
        text-transform: uppercase;

        tr, th {
          background-color: #2e3092;
          color: #fff;
          font-weight: 500;
        }

        tr:first-of-type {
          border-bottom-color: #fff;
        }
      }
    }
  `],
  template: `
    <table class="table table-striped-columns table-sm table-bordered">
      <thead>
      <tr>
        <th colspan="4">Barême de cotisations entreprise</th>
      </tr>
      <tr>
        <th>
          Effectif <br>
          (Nbre salariés)
        </th>
        <th>Montant</th>
        <th>
          Effectif <br>
          (Nbre salariés)
        </th>
        <th>Montant</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let price of prices|slice:0:(prices.length / 2); index as i">
        <ng-container *ngFor="let subPrice of getPriceTableRow(i)">
          <td>
            <ng-container *ngIf="subPrice.max; else noMaxPrice">{{subPrice.min}} à {{subPrice.max}}</ng-container>
            <ng-template #noMaxPrice>+ de {{subPrice.min}}</ng-template>
          </td>
          <td>
            {{subPrice.amount}} €
          </td>
        </ng-container>
      </tr>
      </tbody>
    </table>`,

})
export class PriceTableComponent implements OnInit {
  prices: Price[] = [];

  constructor(private feeService: FeeService) {
  }

  ngOnInit(): void {
    this.feeService.getPriceList().subscribe(prices => this.prices = prices);
  }

  getPriceTableRow(index: number): Price[] {
    return [
      this.prices[index],
      this.prices[index + (this.prices.length / 2)],
    ];
  }
}
