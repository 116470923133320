import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  constructor(private http: HttpClient) {
  }

  send = (formData: FormData): Observable<any[]> => {
    return this.http.post<any[]>(
      environment.baseUrl + '/formCompany',
      formData,
    );
  };

  getPaymentInputs(id: number) {
    return this.http.get<any[]>(`${environment.baseUrl}/payment/company/${id}`);
  }

  createInvoice = (data: any) => {
    return this.http.post<any[]>(
      'https://ude-medef.vosfactures.fr/invoices.json',
      data,
    );
  };
}
