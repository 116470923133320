import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SeniorService {

  constructor(private http: HttpClient) {}

  send = (formData: FormData): Observable<any[]> => {
    return this.http.post<any[]>(environment.baseUrl + '/formSenior', formData);
  }

  getPaymentInputs(id: number) {
    return this.http.get<any[]>(`${environment.baseUrl}/payment/senior/${id}`);
  }
}
