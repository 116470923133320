 //cotisation fn(effectif)
 export const cotisationData: { minRange: number , maxRange: number, amount: number }[] = [
  { minRange: 0 , maxRange: 2, amount: 133 },
  { minRange: 3 , maxRange: 6, amount: 270.83 },
  { minRange: 7 , maxRange: 10, amount: 429.17 },
  { minRange: 11 , maxRange: 15, amount: 562.5 },
  { minRange: 16 , maxRange: 19, amount: 708.33 },
  { minRange: 20 , maxRange: 25, amount: 1000 },
  { minRange: 26 , maxRange: 29, amount: 1187.5 },
  { minRange: 30 , maxRange: 39, amount: 1291.67 },
  { minRange: 40 , maxRange: 49, amount: 1458.33 },
  { minRange: 50 , maxRange: 59, amount: 1666.67 },
  { minRange: 60 , maxRange: 79, amount: 1916 },
  { minRange: 80 , maxRange: 99, amount: 2312.5 },
  { minRange: 100 , maxRange: 250, amount: 3125 },
  { minRange: 251 , maxRange: Number.POSITIVE_INFINITY, amount: 3958.33 },
];