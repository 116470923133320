<div class="container mw-100">
  <div class="wrapper card border-0 pb-5">
    <h1 class="text-center my-3">Bulletin d'Adhésion Sénior en ligne</h1>
    <div class="header">
      <ul>
        <li class="active form_1_progressbar" #form_1_progressbar>
          <div>
            <p>1</p>
          </div>
        </li>
        <li class="form_2_progressbar" #form_2_progressbar>
          <div>
            <p>2</p>
          </div>
        </li>
      </ul>
    </div>

    <div class="form_wrap">
      <div class="form_1 data_info" #form_1>
        <h2>Nos Informations</h2>
        <form (ngSubmit)="saveData()" [formGroup]="registerSeniorInformationForm">

          <div class="row my-3">
            <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label class="required" for="lastName">Nom</label>
                <input type="text" formControlName="lastName" class="form-control" id="lastName"
                       aria-describedby="lastName Help" placeholder="Entrer votre nom"
                       [ngClass]="{'is-invalid': lastName.invalid && (lastName.dirty || lastName.touched)}">
                <div class="invalid-feedback">
                  <span *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)">
                    Le nom est requis
                  </span>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label class="required" for="firstName">Prénom</label>
                <input formControlName="firstName" type="text" class="form-control" id="firstName"
                       aria-describedby="firstName Help" placeholder="Entrer votre prénom"
                       [ngClass]="{'is-invalid': firstName.invalid && (firstName.dirty || firstName.touched)}">
                <div class="invalid-feedback">
                  <span *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)">
                    Le prénom est requis
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="row my-3">
            <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label class="required" for="inputcompanyName">Date de naissance</label>
                <input type="date" formControlName="birthdate" class="form-control" id="inputBirthDate"
                       aria-describedby="birthDate Help" placeholder="Entrer votre date de naissance"
                       [ngClass]="{'is-invalid': birthdate.invalid && (birthdate.dirty || birthdate.touched)}">
                <div class="invalid-feedback">
                  <span *ngIf="birthdate.invalid && (birthdate.dirty || birthdate.touched)">
                    La date est requise
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="row my-3">
            <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label class="required" for="lastProfessionalActivity">Dernière activité professionnelle (fonction +
                  entreprise)</label>
                <input type="texte" class="form-control" id="lastProfessionalActivity"
                       formControlName="lastProfessionalActivity"
                       [ngClass]="{'is-invalid': lastProfessionalActivity.invalid && (lastProfessionalActivity.dirty || lastProfessionalActivity.touched)}"
                       aria-describedby="lastProfessionalActivity Help"
                       placeholder="Entrer la dernière activité">
                <div class="invalid-feedback">
                  <span
                    *ngIf="lastProfessionalActivity.invalid && (lastProfessionalActivity.dirty || lastProfessionalActivity.touched)">
                    La dernière activité professionnelle est requise
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="row my-3">
            <div class="col-sm-12">
              <div class="form-group">
                <label class="required" for="address">Adresse</label>
                <input type="texte"
                       [ngClass]="{'is-invalid': address.invalid && (address.dirty || address.touched)}"
                       class="form-control" id="address" formControlName="address"
                       aria-describedby="address Help" placeholder="Entrer l'adresse">
                <div class="invalid-feedback">
                  <span *ngIf="address.invalid && (address.dirty || address.touched)">
                    L'adresse est requise
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="row my-3">
            <div class="col-sm-12">
              <div class="form-group">
                <label for="addressComplement">Complément d'Adresse</label>
                <input type="texte" class="form-control" id="addressComplement"
                       aria-describedby="addressComplement Help"
                       placeholder="Entrer un complément d'adresse" formControlName="addressComplement">
              </div>
            </div>
          </div>
          <div class="row my-3">
            <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label class="required" for="postalCode">Code Postal</label>
                <input type="text" class="form-control" id="postalCode" formControlName="postalCode"
                       aria-describedby="postalCode Help" placeholder="Entrer le code postal"
                       [ngClass]="{'is-invalid': postalCode.invalid && (postalCode.dirty || postalCode.touched)}">
                <div class="invalid-feedback">
                  <span *ngIf="postalCode.invalid && (postalCode.dirty || postalCode.touched)">
                    Le code postal est requis
                  </span>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label class="required" for="city">Ville</label>
                <input type="text" class="form-control" id="city" formControlName="city"
                       aria-describedby="city Help" placeholder="Entrer la ville"
                       [ngClass]="{'is-invalid': city.invalid && (city.dirty || city.touched)}">
                <div class="invalid-feedback">
                  <span *ngIf="city.invalid && (city.dirty || city.touched)">
                    La ville est requise
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="row my-3">
            <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label for="fixePhone">Téléphone fixe</label>
                <input type="tel"
                       [ngClass]="{'is-invalid': fixePhone.invalid && (fixePhone.dirty || fixePhone.touched )}"
                       formControlName="fixePhone" class="form-control" id="fixePhone"
                       aria-describedby="fixePhone Help" placeholder="Entrer votre téléphone fixe">
                <div class="invalid-feedback">
                  <span *ngIf="fixePhone.invalid && (fixePhone.dirty || fixePhone.touched)">
                    Le numéro de téléphone fixe est requis
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="row my-3">
            <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label class="required" for="mobilePhone">Téléphone mobile</label>
                <input type="tel" class="form-control" id="mobilePhone"
                       [ngClass]="{'is-invalid': mobilePhone.invalid && (mobilePhone.dirty || mobilePhone.touched )}"
                       formControlName="mobilePhone" aria-describedby="mobilePhone Help"
                       placeholder="Entrer votre téléphone mobile">
                <div class="invalid-feedback">
                  <span *ngIf="mobilePhone.invalid && (mobilePhone.dirty || mobilePhone.touched)">
                    Le numéro de téléphone mobile est requis
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="row my-3">
            <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label class="required" for="email">Email</label>
                <input type="text" class="form-control" formControlName="email"
                       [ngClass]="{'is-invalid': email.invalid && (email.dirty || email.touched )}"
                       id="email" aria-describedby="email Help" placeholder="Entrer votre email">
                <div class="invalid-feedback">
                  <div *ngIf="email.invalid && (email.dirty || email.touched)">
                    L'email est requis
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr>
          <div class="my-3">
            <h3 class="text-center my-3">Communication</h3>
            <h6>J'accepte de recevoir les communications de l'UDE par:</h6>
            <p>Mail:</p>
            <div class="form-check">
              <input class="form-check-input" type="radio" formControlName="receiveCommunicationsByEMail"
                     id="receiveCommunicationsByEMail1" value="email">
              <label class="form-check-label" for="receiveCommunicationsByEMail1">
                Oui
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" formControlName="receiveCommunicationsByEMail"
                     id="receiveCommunicationsByEMail2" value="none">
              <label class="form-check-label" for="receiveCommunicationsByEMail2">
                Non
              </label>
            </div>
          </div>
          <div class="my-3">
            <p>WhatsApp:</p>
            <div class="form-check">
              <input class="form-check-input" type="radio"
                     formControlName="receiveCommunicationsByWhatsApp" id="ReceiveCommunicationsByWhatsApp1"
                     value="whatsApp">
              <label class="form-check-label" for="ReceiveCommunicationsByWhatsApp1">
                Oui
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio"
                     formControlName="receiveCommunicationsByWhatsApp" id="ReceiveCommunicationsByWhatsApp2"
                     value="none">
              <label class="form-check-label" for="ReceiveCommunicationsByWhatsApp2">
                Non
              </label>
            </div>
          </div>
          <h3 class="my-3">Droit à l'image</h3>
          <hr>
          <div class="form-check my-3">
            <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"
                   formControlName="authorizeMyImage">
            <label class="form-check-label" for="flexCheckChecked">
              J’autorise l’UDE à capter et à exploiter à titre gracieux mon image sur tous les supports de
              diffusion de l’UDE-MEDEF (livrets, réseaux sociaux, e-mailing, site internet…) et en tout
              formats, dans la limite de la durée maximale autorisée par la CNIL.
            </label>
          </div>
        </form>
      </div>

      <div class="form_2 data_info" style="display: none;" #form_2>
        <h2>Cotisation et modalités de paiement</h2>
        <form [formGroup]="registerSeniorCotisationForm">

          <div class="row my-3">
            <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label>Montant de la cotisation (€)</label>
                <input type="text" class="form-control" [disabled]="true" [readonly]="true" value="50€">
                <div class="invalid-feedback">
                  <span
                    *ngIf="subscriptionAmount.invalid && (subscriptionAmount.dirty || subscriptionAmount.touched)">
                    Le montant de la cotisation est requise
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="row my-3">
            <div class="col-sm-12">
              <label>Modalités de règlement</label>
              <div class="form-check">
                <input class="form-check-input" type="radio" formControlName="terme"
                       value="onlineCreditCard" id="flexRadioTermt1"
                       (click)="handleSetTerme('onlineCreditCard')">
                <label class="form-check-label" for="flexRadioTermt1">
                  Carte bleue en ligne
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" formControlName="terme"
                       value="onsiteCreditCard" id="flexRadioTermt2"
                       (click)="handleSetTerme('onsiteCreditCard')">
                <label class="form-check-label" for="flexRadioTermt2">
                  Carte bleue (sur place ou par téléphone au 0590 26 83 58)
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" formControlName="terme"
                       value="chequePayable" id="flexRadioTermt3"
                       (click)="handleSetTerme('chequePayable')">
                <label class="form-check-label" for="flexRadioTermt3">
                  Chèque à l'ordre de l'Union Des Entreprises - Medef Guadeloupe
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" formControlName="terme"
                       value="bankTransfer" id="flexRadioTermt4" (click)="handleSetTerme('bankTransfer')">
                <label class="form-check-label" for="flexRadioTermt4">
                  Virement - libellé "NOM DE VOTRE ENTREPRISE" - COTISATION UDE MEDEF {{year}}
                </label>
              </div>
            </div>
            <div *ngIf="showPartChequePayable">
              <div class="row my-3">
                <div class="col-md-8 col-sm-12">
                  <div class="form-group">
                    <label for="checkNumber">N° de chèque</label>
                    <input type="number"
                           [ngClass]="{'is-invalid': registerSeniorCotisationForm.get('checkNumber')?.invalid && (registerSeniorCotisationForm.get('checkNumber')?.dirty || registerSeniorCotisationForm.get('checkNumber')?.touched )}"
                           formControlName="checkNumber" class="form-control" id="checkNumber"
                           aria-describedby="checkNumber Help"
                           placeholder="Entrer votre numéro de chèque">
                    <div class="invalid-feedback">
                      <span
                        *ngIf="registerSeniorCotisationForm.get('checkNumber')?.invalid && (registerSeniorCotisationForm.get('checkNumber')?.dirty || registerSeniorCotisationForm.get('checkNumber')?.touched)">
                        Le numéro du chèque est requis
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="showPartBankTransfer">
              <div class="row my-3">
                <div class="col-md-8 col-sm-12">
                  <div class="form-group">
                    <label for="transferNumber">N° de virement</label>
                    <input type="number"
                           [ngClass]="{'is-invalid': registerSeniorCotisationForm.get('transferNumber')?.invalid && (registerSeniorCotisationForm.get('transferNumber')?.dirty || registerSeniorCotisationForm.get('transferNumber')?.touched )}"
                           formControlName="transferNumber" class="form-control" id="transferNumber"
                           aria-describedby="transferNumber Help"
                           placeholder="Entrer le numéro de virement">
                    <i class="text-muted small">IBAN : FR76 1010 7004 7300 4350 4665 814 / Code BIC: BREDFRPPXXX</i>
                    <div class="invalid-feedback">
                      <span
                        *ngIf="registerSeniorCotisationForm.get('transferNumber')?.invalid && (registerSeniorCotisationForm.get('transferNumber')?.dirty || registerSeniorCotisationForm.get('transferNumber')?.touched)">
                        Le numéro du virement est requis
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <h5 class="my-3 text-center lead">L'ADHÉSION À L'UDE-MEDEF VAUT ÉGALEMENT ADHÉSION PLEINE ET
              ENTIÈRE À LA CHARTE DE
              L'ORGANISATION</h5>
            <hr>
            <div class="row my-3">
              <div class="col-md-8 col-sm-12">
                <div class="form-group">
                  <label for="location">Fait à</label>
                  <input type="text" class="form-control" id="location"
                         [ngClass]="{'is-invalid': registerSeniorCotisationForm.get('location')?.invalid && (registerSeniorCotisationForm.get('location')?.dirty || registerSeniorCotisationForm.get('location')?.touched )}"
                         formControlName="location" aria-describedby="location Help"
                         placeholder="Entrer le lieu">
                  <div class="invalid-feedback">
                    <span
                      *ngIf="registerSeniorCotisationForm.get('location')?.invalid && (registerSeniorCotisationForm.get('location')?.dirty || registerSeniorCotisationForm.get('location')?.touched)">
                      Le lieu est requis
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row my-3">
              <div class="col-md-8 col-sm-12">
                <div class="form-group">
                  <label for="datePayment">Le</label>
                  <input type="date" class="form-control" id="datePayment"
                         [ngClass]="{'is-invalid': registerSeniorCotisationForm.get('datePayment')?.invalid && (registerSeniorCotisationForm.get('datePayment')?.dirty || registerSeniorCotisationForm.get('datePayment')?.touched )}"
                         formControlName="datePayment" aria-describedby="datePayment Help"
                         placeholder="Entrer la date de paiement">
                  <div class="invalid-feedback">
                    <span
                      *ngIf="registerSeniorCotisationForm.get('datePayment')?.invalid && (registerSeniorCotisationForm.get('datePayment')?.dirty || registerSeniorCotisationForm.get('datePayment')?.touched)">
                      La date de paiement est requise
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row my-3">
              <div class="col-md-8 col-sm-12">
                <label for="">SIGNATURE & CACHET ENTREPRISE</label>
                <app-canvas-custom
                  formControlName="signature"
                  [method]="action"
                  (exportImage)="handleExportImage($event)"></app-canvas-custom>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="btns_wrap">
      <div class="common_btns form_1_btns" #form_1_btns>
        <button (click)="handleFill()" type="button" class="btn btn-primary d-none">remplir</button>
        <button (click)="handleBtn1Next()" [disabled]="registerSeniorInformationForm.invalid" type="button"
                class="btn btn-primary  btn_next">Suivant
          <span class="icon">
            <i class="fa-solid fa-arrow-right"></i>
          </span>
        </button>
      </div>

      <!--   <div class="common_btns form_2_btns" #form_2_btns style="display: none;">
            <button (click)="handleBtn2Back()" type="button" class="btn btn-primary  btn_back">
                <span class="icon">
                    <i class="fa-solid fa-arrow-left"></i>
                </span>
                Précedent
            </button>
            <button (click)="handleBtn2Next()" type="button" class="btn btn-primary  btn_next">Suivant
                <span class="icon">
                    <i class="fa-solid fa-arrow-right"></i>
                </span>
            </button>
        </div> -->
      <div class="common_btns form_2_btns" #form_2_btns style="display: none;">
        <button (click)="handleBtn2Back()" type="button" class="btn btn-primary  btn_back">
          <span class="icon">
            <i class="fa-solid fa-arrow-left"></i>
          </span>
          Précedent
        </button>
        <button (click)="send()" type="button" class="btn btn-primary  btn_end"
                [disabled]="!registerSeniorCotisationForm.valid">
          Terminer
          <span class="icon"></span>
        </button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="debug" class="container">
  <div class="row">
    <div class="col-md-4 col-sm-12">
      <div>
        <h2>Nos Informations</h2>
        <pre>
                    <ul class="list-group">
                        <li class="list-group-item">
                            {{ registerSeniorInformationForm.value | json }}
                        </li>
                    </ul>
                </pre>
      </div>
    </div>

    <div class="col-md-4 col-sm-12">
      <h2>Cotisation</h2>
      <pre>
                <ul class="list-group">
                    <li class="list-group-item">
                         {{ registerSeniorCotisationForm.value | json }}
                    </li>
                </ul>
            </pre>
    </div>
  </div>
</div>

<ng-template #paymentModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Paiement de vos cotisations</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>

  <div class="modal-body">
    <p class="text-center">Procédez au paiement de vos cotisations grâce à notre partenaire de paiement</p>
    <img src="assets/images/logo_systempay.png" alt="systempay" class="d-block mx-auto">
    <div class="mb-3">
      <form action="https://paiement.systempay.fr/vads-payment/" method="POST" ngNoForm>
        <div class="row">
          <div class="d-none mb-3 col-4" *ngFor="let input of paymentInputs">
            <input type="text" [name]="input.key" value="{{input.value}}" class="form-control form-control-sm">
          </div>
        </div>
        <input type="submit" name="payer" value="Payer"
               class="btn btn-pay d-block mx-auto mt-4 px-4 text-uppercase fw-semibold"/>
      </form>
    </div>
  </div>
</ng-template>
