<div class="container py-4 text-center">
  <h1 class="h3 mb-4">Paiement de vos cotisations - MEDEF Guadeloupe</h1>

  <ng-container *ngIf="type === 'card'">
    <ng-container *ngIf="status === 'erreur'">
      <div class="text-danger fw-semibold h4">Erreur</div>
      <p class="text-black">
        Une erreur s'est produite lors de votre paiement. <br>
        Merci de bien vouloir réessayer. <br><br>
        Si le problème persiste contactez l'administration
      </p>
    </ng-container>

    <ng-container *ngIf="status === 'autorise'">
      <div class="text-success fw-semibold h4">Paiement autorisé</div>
      <p class="text-black">
        Merci, votre paiement a été accepté et votre facture vous a été envoyée sur l'adresse e-mail renseignée.
      </p>
    </ng-container>

    <ng-container *ngIf="status === 'refuse'">
      <div class="text-danger fw-semibold h4">Paiement refusé</div>
      <p class="text-black">
        Votre transaction a été refusée par notre partenaire de paiement. <br>
        Vérifiez bien les informations de votre carte et reportez vous au message d'erreur afin d'en savoir plus sur les
        raisons de ce refus.
      </p>
    </ng-container>

    <ng-container *ngIf="status === 'annule'">
      <div class="text-warning fw-semibold h4">Paiement annulé</div>
      <p class="text-black">
        Vous avez décidé d'annuler votre paiement. <br>
        ...
      </p>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="type === 'other'">
    <div class="text-success fw-semibold h4">Adhésion prise en compte</div>
    <p class="text-black">
      Merci de consulter votre boîte mail. Vous devriez avoir reçu un e-mail récapitulatif contenant la procédure afin
      de finaliser votre adhésion
    </p>
  </ng-container>
</div>
