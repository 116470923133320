<div class="wrapper">
  <canvas #canvas></canvas>
  <div class="btns">
    <button (click)="handleClear()" class="btn btn-primary">Effacer</button>
    <button
      *ngIf="isVisible"
      (click)="handleValidate()"
      class="btn btn-primary"
    >
      Valider
    </button>
  </div>
</div>
