import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { legalForms } from 'src/app/datas/legal';
import { cotisationData } from 'src/app/datas/cotisation';
import { validatorPhoneFrNumber } from 'src/app/validators/validators-custom';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SeniorService } from 'src/app/services/senior.service';
import { Router } from '@angular/router';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-register-senior',
  templateUrl: './register-senior.component.html',
  styleUrls: ['./register-senior.component.scss'],
})
export class RegisterSeniorComponent implements OnInit {
  debug = false;
  paymentInputs: { key: string, value: string }[] = [];
  registerSeniorInformationForm!: FormGroup;
  /*   registerSeniorAuthorizeMyImageForm!: FormGroup; */
  registerSeniorCotisationForm!: FormGroup;

  /* showNumberUrsaf: boolean = false; */
  // For cotisations
  showPartOnlineCreditCard: boolean = true;
  showPartOnsiteCreditCard: boolean = false;
  showPartChequePayable: boolean = false;
  showPartBankTransfer: boolean = false;
  showNbrInstalments: boolean = false;

  legalForms = legalForms;

  // signature
  imageSignature!: string;

  //modal
  @ViewChild('paymentModal', {static: true}) paymentModal!: NgbModalRef;
  @ViewChild('modal', {static: true}) modal!: NgbModalRef;
  @ViewChild('form_1', {static: true}) form_1!: ElementRef;
  @ViewChild('form_2', {static: true}) form_2!: ElementRef;
  @ViewChild('form_3', {static: true}) form_3!: ElementRef;

  @ViewChild('form_1_btns', {static: true}) form_1_btns!: ElementRef;
  @ViewChild('form_2_btns', {static: true}) form_2_btns!: ElementRef;
  @ViewChild('form_3_btns', {static: true}) form_3_btns!: ElementRef;

  @ViewChild('form_1_progressbar', {static: true})
  form_1_progressbar!: ElementRef;
  @ViewChild('form_2_progressbar', {static: true})
  form_2_progressbar!: ElementRef;
  @ViewChild('form_3_progressbar', {static: true})
  form_3_progressbar!: ElementRef;

  //cotisation fn(effectif)
  cotisationData: { minRange: number; maxRange: number; amount: number }[] =
    cotisationData;

  // for canvas
  action!: string;
  year!: number;

  constructor(
    private fb: FormBuilder,
    private modalService: NgbModal,
    private seniorService: SeniorService,
    private router: Router,
  ) {
    let now = DateTime.now();
    if (now.month >= 12) {
      now = now.plus({year: 1});
    }
    this.year = now.year;
  }

  ngOnInit(): void {
    this.init();
  }

  init = () => {
    this.registerSeniorInformationForm = this.fb.group({
      lastName: ['', Validators.required],
      firstName: ['', Validators.required],
      birthdate: ['', Validators.required],
      lastProfessionalActivity: ['', Validators.required],
      address: ['', Validators.required],
      addressComplement: '',
      postalCode: ['', Validators.required],
      city: ['', Validators.required],
      fixePhone: ['', validatorPhoneFrNumber],
      mobilePhone: ['', [validatorPhoneFrNumber, Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      receiveCommunicationsByEMail: 'email',
      receiveCommunicationsByWhatsApp: 'whatsApp',
      authorizeMyImage: [false],
    });

    this.registerSeniorCotisationForm = this.fb.group({
      /* nbrMembersCompanies: [0], */
      /*  headcount: [0, Validators.required], */
      subscriptionAmount: [50, Validators.required],
      terme: 'onlineCreditCard',
      paymentInInstalments: [false],
      location: ['', Validators.required],
      datePayment: ['', Validators.required],
      onlineCreditCardField: [''],
      checkNumber: null,
      transferNumber: null,
      nbrInstalments: [],
      signature: [null, Validators.required],
    });
  };

  contacts: {
    id: number;
    lastName?: string;
    firstName?: string;
    job?: string;
    phone?: string;
    receiveCommunicationsByEMail_ocf?: string;
    receiveCommunicationsByWhatsApp_ocf?: string;
  }[] = [];

  saveData = () => {
  };

  closeModal = () => {
    const button = document.querySelector('.btn-close') as HTMLButtonElement;
    button?.click();
  };

  handleSetTerme = (term: string) => {
    this.showPartOnlineCreditCard = false;
    this.showPartOnsiteCreditCard = false;
    this.showPartChequePayable = false;
    this.showPartBankTransfer = false;
    const checkNumberControl =
      this.registerSeniorCotisationForm.get('checkNumber');
    const transferNumberControl =
      this.registerSeniorCotisationForm.get('transferNumber');

    switch (term) {
      case 'onlineCreditCard':
        this.showPartOnlineCreditCard = true;
        checkNumberControl?.clearValidators();
        checkNumberControl?.setValue(null);
        transferNumberControl?.clearValidators();
        transferNumberControl?.setValue(null);
        break;

      case 'onsiteCreditCard':
        this.showPartOnsiteCreditCard = true;
        checkNumberControl?.clearValidators();
        checkNumberControl?.setValue(null);
        transferNumberControl?.clearValidators();
        transferNumberControl?.setValue(null);
        break;

      case 'chequePayable':
        this.showPartChequePayable = true;
        transferNumberControl?.clearValidators();
        transferNumberControl?.setValue(null);
        break;

      case 'bankTransfer':
        this.showPartBankTransfer = true;
        checkNumberControl?.clearValidators();
        checkNumberControl?.setValue(null);
        break;
    }
  };

  handleExportImage = (image: string) => {
    this.imageSignature = image;
  };

  handleFill = () => {
    this.registerSeniorInformationForm.patchValue({
      lastName: 'Adam',
      firstName: 'Guillaume',
      birthdate: '1990-09-27',
      lastProfessionalActivity: 'Développeur, USTS',
      address: '35 rue de la Ziegelau',
      addressComplement: '',
      postalCode: '67100',
      city: 'Strasbourg',
      mobilePhone: '0601020304',
      email: 'guillaume@gadam.fr',
      receiveCommunicationsByEMail: 'email',
      receiveCommunicationsByWhatsApp: 'none',
    });

    this.registerSeniorCotisationForm.patchValue({
      location: 'Paris',
      datePayment: (new Date()).toISOString().split('T')[0],
    })
  };

  choicePaymentInInstalments = (event: any) => {
    const nbrInstalments =
      this.registerSeniorCotisationForm.get('nbrInstalments');

    if (event.target.checked) {
      this.showNbrInstalments = true;
    } else {
      this.showNbrInstalments = false;
      nbrInstalments?.clearValidators();
      nbrInstalments?.setValue(null);
    }
  };

  handleBtn1Next = () => {
    this.form_1.nativeElement.style.display = 'none';
    this.form_2.nativeElement.style.display = 'block';

    this.form_1_btns.nativeElement.style.display = 'none';
    this.form_2_btns.nativeElement.style.display = 'flex';
    this.form_2_progressbar.nativeElement.classList.add('active');
    window.scrollTo(0, 0);
  };

  handleBtn2Back = () => {
    this.form_1.nativeElement.style.display = 'block';
    this.form_2.nativeElement.style.display = 'none';

    this.form_1_btns.nativeElement.style.display = 'flex';
    this.form_2_btns.nativeElement.style.display = 'none';
    this.form_2_progressbar.nativeElement.classList.remove('active');
    window.scrollTo(0, 0);
  };

  handleBtn2Next = () => {
    this.form_2.nativeElement.style.display = 'none';
    this.form_3.nativeElement.style.display = 'block';

    this.form_2_btns.nativeElement.style.display = 'none';
    this.form_3_btns.nativeElement.style.display = 'flex';
    this.form_3_progressbar.nativeElement.classList.add('active');
    window.scrollTo(0, 0);
  };

  handleBtn3Back = () => {
    this.form_2.nativeElement.style.display = 'block';
    this.form_3.nativeElement.style.display = 'none';

    this.form_2_btns.nativeElement.style.display = 'flex';
    this.form_3_btns.nativeElement.style.display = 'none';

    this.form_3_progressbar.nativeElement.classList.remove('active');
    window.scrollTo(0, 0);
  };

  handleClear = () => {
    this.action = 'clear';
  };

  send = () => {
    const formData = new FormData();

    // Informations
    const dataInfo = this.registerSeniorInformationForm.value;
    for (var key in dataInfo) {
      formData.append(key, dataInfo[key]);
    }

    // Signature
    if (this.imageSignature) {
      formData.append('signature', this.imageSignature);
    }

    // Our Contacts
    // formData.append('contacts', JSON.stringify(this.contacts));

    // Cotisation
    const dataCotisation = this.registerSeniorCotisationForm.value;
    for (var key in dataCotisation) {
      formData.append(key, dataCotisation[key]);
    }

    this.seniorService.send(formData).subscribe((resp: any) => {
      if (resp) {
        const id = resp.registration.id;

        if (this.registerSeniorCotisationForm.value.terme !== 'onlineCreditCard') {
          this.router.navigate(['/recapitulatif'])
        } else {
          this.seniorService.getPaymentInputs(id).subscribe(res => {
            for (const [key, value] of Object.entries(res)) {
              this.paymentInputs.push({key: key, value: value});
            }
            this.modalService.open(this.paymentModal);
          });
        }
      }
    })
  };

  checkIfNumber = (str: string) => {
    var n = Math.floor(Number(str));
    return n !== Infinity && String(n) === str && n >= 0;
  };

  //gets
  get lastName() {
    return this.registerSeniorInformationForm.controls['lastName'];
  }

  get firstName() {
    return this.registerSeniorInformationForm.controls['firstName'];
  }

  get birthdate() {
    return this.registerSeniorInformationForm.controls['birthdate'];
  }

  /* get undersignedLastName() {
    return this.registerSeniorInformationForm.controls['undersignedLastName'];
  } */

  /* get undersignedBirthdate() {
    return this.registerSeniorInformationForm.controls['undersignedBirthdate'];
  } */

  /*  get companyName() {
    return this.registerSeniorInformationForm.controls['companyName'];
  } */
  get lastProfessionalActivity() {
    return this.registerSeniorInformationForm.controls[
      'lastProfessionalActivity'
      ];
  }

  /*
  get tradeName() {
    return this.registerSeniorInformationForm.controls['tradeName'];
  } */

  get address() {
    return this.registerSeniorInformationForm.controls['address'];
  }

  get postalCode() {
    return this.registerSeniorInformationForm.controls['postalCode'];
  }

  get city() {
    return this.registerSeniorInformationForm.controls['city'];
  }

  /*  get job() {
    return this.registerSeniorInformationForm.controls['job'];
  } */

  /* get siretNumber() {
    return this.registerSeniorInformationForm.controls['siretNumber'];
  }

  get professionalActivity() {
    return this.registerSeniorInformationForm.controls['professionalActivity'];
  }

  get legalForm() {
    return this.registerSeniorInformationForm.controls['legalForm'];
  } */

  get fixePhone() {
    return this.registerSeniorInformationForm.controls['fixePhone'];
  }

  get mobilePhone() {
    return this.registerSeniorInformationForm.controls['mobilePhone'];
  }

  get email() {
    return this.registerSeniorInformationForm.controls['email'];
  }

  get subscriptionAmount() {
    return this.registerSeniorCotisationForm.controls['subscriptionAmount'];
  }
}
