import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { legalForms } from 'src/app/datas/legal';
import { cotisationData } from 'src/app/datas/cotisation';
import { validatorPhoneFrNumber, validatorSiretNumber } from 'src/app/validators/validators-custom';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AssociationService } from 'src/app/services/association.service';
import { DateTime } from 'luxon';
import { FeeService } from '../../services/fee.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-register-association',
  templateUrl: './register-association.component.html',
  styleUrls: ['./register-association.component.scss'],
})
export class RegisterAssociationComponent implements OnInit {
  debug = false;

  paymentInputs: { key: string, value: string }[] = [];

  registerAssociationInformationForm!: FormGroup;
  registerAssociationOurContactsForm!: FormGroup;
  registerAssociationAuthorizeMyImageForm!: FormGroup;
  registerAssociationCotisationForm!: FormGroup;

  showNumberUrsaf: boolean = false;
  // For cotisations
  showPartOnlineCreditCard: boolean = true;
  showPartOnsiteCreditCard: boolean = false;
  showPartChequePayable: boolean = false;
  showPartBankTransfer: boolean = false;
  showNbrInstalments: boolean = false;

  legalForms = legalForms;
  modeViewModal: 'create' | 'duplicate' | 'edit' = 'create';

  // signature
  imageSignature!: string;

  //modal
  @ViewChild('paymentModal', {static: true}) paymentModal!: NgbModalRef;
  @ViewChild('modal', {static: true}) modal!: NgbModalRef;
  @ViewChild('form_1', {static: true}) form_1!: ElementRef;
  @ViewChild('form_2', {static: true}) form_2!: ElementRef;
  @ViewChild('form_3', {static: true}) form_3!: ElementRef;

  @ViewChild('form_1_btns', {static: true}) form_1_btns!: ElementRef;
  @ViewChild('form_2_btns', {static: true}) form_2_btns!: ElementRef;
  @ViewChild('form_3_btns', {static: true}) form_3_btns!: ElementRef;

  @ViewChild('form_1_progressbar', {static: true})
  form_1_progressbar!: ElementRef;
  @ViewChild('form_2_progressbar', {static: true})
  form_2_progressbar!: ElementRef;
  @ViewChild('form_3_progressbar', {static: true})
  form_3_progressbar!: ElementRef;

  //cotisation fn(effectif)
  cotisationData: { minRange: number; maxRange: number; amount: number }[] =
    cotisationData;

  // for canvas
  action!: string;
  isRenewal: boolean = false;
  fees: number = 0;
  year: number;

  constructor(
    private fb: FormBuilder,
    private modalService: NgbModal,
    private associationService: AssociationService,
    private feeService: FeeService,
    private router: Router,
  ) {
    let now = DateTime.now();
    if (now.month >= 12) {
      now = now.plus({year: 1});
    }
    this.year = now.year;
    this.feeService.getFees('federation', this.isRenewal).subscribe(fees => this.fees = fees.fees);
  }

  ngOnInit(): void {
    this.init();
  }

  init = () => {
    this.registerAssociationInformationForm = this.fb.group({
      subscription: 'newSubscribtion',
      lastName: [null, Validators.required],
      firstName: [null, Validators.required],
      birthdate: [null, Validators.required],
      companyName: ['', Validators.required],
      address: ['', Validators.required],
      addressComplement: '',
      postalCode: ['', Validators.required],
      city: ['', Validators.required],
      job: ['', Validators.required],
      siretNumber: ['', validatorSiretNumber],
      apeCode: '',
      professionalActivity: ['', Validators.required],
      legalForm: [null, Validators.required],
      legalFormOther: [null],
      mobilePhone: ['', [validatorPhoneFrNumber, Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      receiveCommunicationsByEMail: 'email',
      receiveCommunicationsByWhatsApp: 'whatsApp',
    });
    this.registerAssociationOurContactsForm = this.fb.group({
      id: null,
      lastName: ['', Validators.required],
      firstName: ['', Validators.required],
      phone: ['', [validatorPhoneFrNumber, Validators.required]],
      job: ['', Validators.required],
      receiveCommunicationsByEMail_ocf: 'email',
      receiveCommunicationsByWhatsApp_ocf: 'none',
    });
    this.registerAssociationAuthorizeMyImageForm = this.fb.group({
      authorizeMyImage: [false],
    });
    this.registerAssociationCotisationForm = this.fb.group({
      nbrMembersCompanies: [0, Validators.required],
      headcount: [0, Validators.required],
      subscriptionAmount: [133],
      terme: 'onlineCreditCard',
      paymentInInstalments: [false],
      location: ['', Validators.required],
      datePayment: ['', Validators.required],
      /* onlineCreditCardField: [''], */
      checkNumber: null,
      transferNumber: null,
      nbrInstalments: [],
      signature: [null, Validators.required],
    });

    this.registerAssociationInformationForm.get('legalForm')?.valueChanges.subscribe(res => {
      if (res === 'null') {
        this.registerAssociationInformationForm.get('legalFormOther')?.setValidators([Validators.required]);
      } else {
        this.registerAssociationInformationForm.get('legalFormOther')?.clearValidators();
      }
      this.registerAssociationInformationForm.get('legalFormOther')?.updateValueAndValidity();
      this.registerAssociationInformationForm.updateValueAndValidity();
    })

    this.registerAssociationInformationForm.get('subscription')?.valueChanges
      .subscribe(res => {
        this.isRenewal = res === 'renewal';
        this.feeService.getFees('federation', this.isRenewal).subscribe(fees => this.fees = fees.fees);
      });
  };

  contacts: {
    id: number;
    lastName?: string;
    firstName?: string;
    job?: string;
    phone?: string;
    receiveCommunicationsByEMail_ocf?: string;
    receiveCommunicationsByWhatsApp_ocf?: string;
  }[] = [];

  saveData = () => {
  };

  openModal = (id: number | null, mode: 'create' | 'duplicate' | 'edit') => {
    let contact;
    if (id) {
      contact = this.contacts.find((c: any) => {
        return c.id === id;
      });
    }

    switch (mode) {
      case 'create':
        this.registerAssociationOurContactsForm.reset({
          lastName: [],
          firstName: [],
          phone: [],
          job: [],
          receiveCommunicationsByEMail_ocf: 'email',
          receiveCommunicationsByWhatsApp_ocf: 'none',
        });

        this.modeViewModal = 'create';
        break;

      case 'duplicate':
        if (contact) {
          this.registerAssociationOurContactsForm.patchValue({
            id: Date.now(),
            lastName: contact.lastName,
            firstName: contact.firstName,
            phone: contact.phone,
            job: contact.job,
            receiveCommunicationsByEMail_ocf:
            contact.receiveCommunicationsByEMail_ocf,
            receiveCommunicationsByWhatsApp_ocf:
            contact.receiveCommunicationsByWhatsApp_ocf,
          });
          this.modeViewModal = 'duplicate';
        }
        break;

      case 'edit':
        if (contact) {
          this.registerAssociationOurContactsForm.patchValue({
            id: contact.id,
            lastName: contact.lastName,
            firstName: contact.firstName,
            phone: contact.phone,
            job: contact.job,
            receiveCommunicationsByEMail_ocf:
            contact.receiveCommunicationsByEMail_ocf,
            receiveCommunicationsByWhatsApp_ocf:
            contact.receiveCommunicationsByWhatsApp_ocf,
          });
          this.modeViewModal = 'edit';
        }
        break;
    }

    this.modalService.open(this.modal, {centered: true});
  };

  closeModal = () => {
    const button = document.querySelector('.btn-close') as HTMLButtonElement;
    button?.click();
  };

  deleteContact = (idContactDelete: number) => {
    const newContacts = this.contacts.filter((c) => {
      return c.id !== idContactDelete;
    });
    this.contacts = newContacts;
  };

  handleSetTerme = (term: string) => {
    this.showPartOnlineCreditCard = false;
    this.showPartOnsiteCreditCard = false;
    this.showPartChequePayable = false;
    this.showPartBankTransfer = false;
    const checkNumberControl =
      this.registerAssociationCotisationForm.get('checkNumber');
    const transferNumberControl =
      this.registerAssociationCotisationForm.get('transferNumber');

    switch (term) {
      case 'onlineCreditCard':
        this.showPartOnlineCreditCard = true;
        checkNumberControl?.clearValidators();
        checkNumberControl?.setValue(null);
        transferNumberControl?.clearValidators();
        transferNumberControl?.setValue(null);
        break;

      case 'onsiteCreditCard':
        this.showPartOnsiteCreditCard = true;
        checkNumberControl?.clearValidators();
        checkNumberControl?.setValue(null);
        transferNumberControl?.clearValidators();
        transferNumberControl?.setValue(null);
        break;

      case 'chequePayable':
        this.showPartChequePayable = true;
        transferNumberControl?.clearValidators();
        transferNumberControl?.setValue(null);
        break;

      case 'bankTransfer':
        this.showPartBankTransfer = true;
        checkNumberControl?.clearValidators();
        checkNumberControl?.setValue(null);
        break;
    }
  };

  create = () => {
    const newContact = this.registerAssociationOurContactsForm.value;
    newContact.id = Date.now();
    this.contacts.push(newContact);
    this.closeModal();
  };

  edit = () => {
    // let newContacts:[]| null = [];
    const newContact = this.registerAssociationOurContactsForm.value;
    if (newContact) {
      let indexToUpdate = this.contacts.findIndex(
        (contact) => contact.id == newContact.id,
      );
      this.contacts[indexToUpdate] = newContact;
    }
    this.closeModal();
  };

  handleExportImage = (image: string) => {
    this.imageSignature = image;
  };

  handleFill = () => {
    this.registerAssociationInformationForm.patchValue({
      subscription: 'newSubscribtion',
      lastName: 'Adam',
      firstName: 'Guillaume',
      birthdate: '1990-09-27',
      companyName: 'USTS',
      address: '1 rue Fulton',
      addressComplement: '',
      postalCode: '75013',
      city: 'Paris',
      job: 'Développeur',
      siretNumber: '80189915400034',
      apeCode: '6201Z',
      professionalActivity: 'Sites internet applications mobiles progiciels SaaS',
      legalForm: 'Société par actions simplifiée (SAS)',
      mobilePhone: '0601020304',
      email: 'guillaume@usts.fr',
      receiveCommunicationsByEMail: 'email',
      receiveCommunicationsByWhatsApp: 'whatsApp',
    });

    this.registerAssociationCotisationForm.patchValue({
      location: 'Paris',
      datePayment: (new Date()).toISOString().split('T')[0],
    })
  };

  choicePaymentInInstalments = (event: any) => {
    const nbrInstalments =
      this.registerAssociationCotisationForm.get('nbrInstalments');

    if (event.target.checked) {
      this.showNbrInstalments = true;
    } else {
      this.showNbrInstalments = false;
      nbrInstalments?.clearValidators();
      nbrInstalments?.setValue(null);
    }
  };

  handleBtn1Next = () => {
    this.form_1.nativeElement.style.display = 'none';
    this.form_2.nativeElement.style.display = 'block';

    this.form_1_btns.nativeElement.style.display = 'none';
    this.form_2_btns.nativeElement.style.display = 'flex';
    this.form_2_progressbar.nativeElement.classList.add('active');
    window.scrollTo(0, 0);
  };

  handleBtn2Back = () => {
    this.form_1.nativeElement.style.display = 'block';
    this.form_2.nativeElement.style.display = 'none';

    this.form_1_btns.nativeElement.style.display = 'flex';
    this.form_2_btns.nativeElement.style.display = 'none';
    this.form_2_progressbar.nativeElement.classList.remove('active');
    window.scrollTo(0, 0);
  };

  handleBtn2Next = () => {
    this.form_2.nativeElement.style.display = 'none';
    this.form_3.nativeElement.style.display = 'block';

    this.form_2_btns.nativeElement.style.display = 'none';
    this.form_3_btns.nativeElement.style.display = 'flex';
    this.form_3_progressbar.nativeElement.classList.add('active');
    window.scrollTo(0, 0);
  };

  handleBtn3Back = () => {
    this.form_2.nativeElement.style.display = 'block';
    this.form_3.nativeElement.style.display = 'none';

    this.form_2_btns.nativeElement.style.display = 'flex';
    this.form_3_btns.nativeElement.style.display = 'none';

    this.form_3_progressbar.nativeElement.classList.remove('active');
    window.scrollTo(0, 0);
  };

  handleClear = () => {
    this.action = 'clear';
  };

  send = () => {
    const formData = new FormData();

    // Informations
    const dataInfo = this.registerAssociationInformationForm.value;
    for (key in dataInfo) {
      formData.append(key, dataInfo[key]);
    }
    const imageInfo = this.registerAssociationAuthorizeMyImageForm.value;
    for (key in imageInfo) {
      formData.append(key, imageInfo[key]);
    }

    // Signature
    if (this.imageSignature) {
      formData.append('signature', this.imageSignature);
    }

    // Our Contacts
    formData.append('contacts', JSON.stringify(this.contacts));

    // Cotisation
    const dataCotisation = this.registerAssociationCotisationForm.value;
    for (var key in dataCotisation) {
      formData.append(key, dataCotisation[key]);
    }

    this.associationService.send(formData).subscribe((resp: any) => {
      if (resp) {
        const id = resp.registration.id;

        if (this.registerAssociationCotisationForm.value.terme !== 'onlineCreditCard') {
          this.router.navigate(['/recapitulatif'])
        } else {
          this.associationService.getPaymentInputs(id).subscribe(res => {
            for (const [key, value] of Object.entries(res)) {
              this.paymentInputs.push({key: key, value: value});
            }
            this.modalService.open(this.paymentModal);
          });
        }
      }
    });
  };

  onChangeEventHeadcount = (event: any) => {
    let value = event.target.value;
    const subscriptionAmount =
      this.registerAssociationCotisationForm.get('subscriptionAmount');
    let index = 0;
    let find = false;

    if (this.checkIfNumber(value)) {
      value = parseInt(event.target.value);
      while (index < this.cotisationData.length && !find) {
        if (
          value >= this.cotisationData[index].minRange &&
          value <= this.cotisationData[index].maxRange
        ) {
          find = true;
          subscriptionAmount?.setValue(this.cotisationData[index].amount);
          break;
        }

        index++;
      }
    }
  };

  checkIfNumber = (str: string) => {
    var n = Math.floor(Number(str));
    return n !== Infinity && String(n) === str && n >= 0;
  };

  //gets
  get lastName() {
    return this.registerAssociationInformationForm.controls['lastName'];
  }

  get firstName() {
    return this.registerAssociationInformationForm.controls['firstName'];
  }

  get birthdate() {
    return this.registerAssociationInformationForm.controls['birthdate'];
  }

  get companyName() {
    return this.registerAssociationInformationForm.controls['companyName'];
  }

  get address() {
    return this.registerAssociationInformationForm.controls['address'];
  }

  get postalCode() {
    return this.registerAssociationInformationForm.controls['postalCode'];
  }

  get city() {
    return this.registerAssociationInformationForm.controls['city'];
  }

  get job() {
    return this.registerAssociationInformationForm.controls['job'];
  }

  get siretNumber() {
    return this.registerAssociationInformationForm.controls['siretNumber'];
  }

  get professionalActivity() {
    return this.registerAssociationInformationForm.controls[
      'professionalActivity'
      ];
  }

  get legalForm() {
    return this.registerAssociationInformationForm.controls['legalForm'];
  }

  get legalFormOther() {
    return this.registerAssociationInformationForm.controls['legalFormOther'];
  }

  get mobilePhone() {
    return this.registerAssociationInformationForm.controls['mobilePhone'];
  }

  get email() {
    return this.registerAssociationInformationForm.controls['email'];
  }

  get nbrMembersCompanies() {
    return this.registerAssociationCotisationForm.controls[
      'nbrMembersCompanies'
      ];
  }

  getFullName(): string {
    return `${this.registerAssociationInformationForm.value.firstName ?? '___'} ${this.registerAssociationInformationForm.value.lastName ?? '___'}`
  }

  getBirthDate(): string {
    if (this.registerAssociationInformationForm.value.birthdate) {
      let date: DateTime = DateTime.fromFormat(this.registerAssociationInformationForm.value.birthdate, 'yyyy-MM-dd');

      return date.setLocale('fr').toLocaleString(DateTime.DATE_SHORT);
    }
    return '___';
  }
}
