import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

export type Price = { min: number, max?: number, amount: number };
export type Fees = { fees: number };

@Injectable({
  providedIn: 'root',
})
export class FeeService {
  constructor(private http: HttpClient) {
  }

  getPriceList(): Observable<Price[]> {
    return this.http.get<Price[]>(environment.baseUrl + '/price-list');
  }

  getFees(type: 'company' | 'federation' | 'senior', renewal: boolean = false, headCount: number = 0): Observable<Fees> {
    return this.http.get<Fees>(environment.baseUrl + `/fees?type=${type}&headCount=${headCount}&renewal=${renewal}`);
  }
}
