import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { fromEvent, pairwise, Subscription, switchMap, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'app-canvas-custom',
  templateUrl: './canvas-custom.component.html',
  styleUrls: ['./canvas-custom.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CanvasCustomComponent,
      multi: true,
    },
  ],
})
export class CanvasCustomComponent implements OnInit, ControlValueAccessor {
  @Input() method!: string;
  @Input() width = 512;
  @Input() height = 418;
  @Output() exportImage = new EventEmitter<string>();
  @ViewChild('canvas') canvas!: ElementRef;
  cx!: CanvasRenderingContext2D;
  drawingSubscription!: Subscription;
  image!: string;
  groupForm!: FormGroup;
  signature!: string;
  isVisible = false;

  onChange: (_: any) => void = (_: any) => {
  };
  onTouch: (_: any) => void = (_: any) => {
  };

  constructor(private fb: FormBuilder) {
  }

  writeValue(image: string): void {
    this.image = image;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  ngOnInit(): void {
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    const widthScreen = event.target.innerWidth;
    if (widthScreen < 768) {
      this.width = 282;
    } else {
      this.width = 512;
    }
  }

  ngAfterViewInit() {
    // get the context
    const canvasEl: HTMLCanvasElement = this.canvas.nativeElement;
    this.cx = canvasEl.getContext('2d') as CanvasRenderingContext2D;

    // set the width and height
    canvasEl.width = this.width;
    canvasEl.height = this.height;

    // set some default properties about the line
    this.cx.lineWidth = 3;
    this.cx.lineCap = 'round';
    this.cx.strokeStyle = '#000';

    // we'll implement this method to start capturing mouse events
    this.captureEvents(canvasEl);
  }

  captureEvents(canvasEl: HTMLCanvasElement) {
    // this will capture all mousedown events from teh canvas element
    this.drawingSubscription = fromEvent(canvasEl, 'mousedown')
      .pipe(
        tap(() => {
          this.isVisible = true;
        }),
        switchMap((e) => {
          // after a mouse down, we'll record all mouse moves
          return fromEvent(canvasEl, 'mousemove').pipe(
            // we'll stop (and unsubscribe) once the user releases the mouse
            // this will trigger a 'mouseup' event
            takeUntil(fromEvent(canvasEl, 'mouseup')),
            // we'll also stop (and unsubscribe) once the mouse leaves the canvas (mouseleave event)
            takeUntil(fromEvent(canvasEl, 'mouseleave')),
            // pairwise lets us get the previous value to draw a line from
            // the previous point to the current point,
            pairwise(),
          );
        }),
      )
      .subscribe((res: any) => {
        const rect = canvasEl.getBoundingClientRect();

        // previous and current position with the offset
        const prevPos = {
          x: res[0].clientX - rect.left,
          y: res[0].clientY - rect.top,
        };

        const currentPos = {
          x: res[1].clientX - rect.left,
          y: res[1].clientY - rect.top,
        };

        // this method we'll implement soon to do the actual drawing
        this.drawOnCanvas(prevPos, currentPos);
      });
  }

  drawOnCanvas(
    prevPos: { x: number; y: number },
    currentPos: { x: number; y: number },
  ) {
    // incase the context is not set
    if (!this.cx) {
      return;
    }

    // start our drawing path
    this.cx.beginPath();

    // we're drawing lines so we need a previous position
    if (prevPos) {
      // sets the start point
      this.cx.moveTo(prevPos.x, prevPos.y); // from
      // draws a line from the start pos until the current position
      this.cx.lineTo(currentPos.x, currentPos.y);

      // strokes the current path with the styles we set earlier
      this.cx.stroke();
    }
  }

  ngOnDestroy() {
    // this will remove event lister when this component is destroyed
    this.drawingSubscription.unsubscribe();
  }

  handleClear = () => {
    const canvasEl: HTMLCanvasElement = this.canvas.nativeElement;
    this.cx.clearRect(0, 0, canvasEl.width, canvasEl.height);
    this.image = '';
    this.exportImage.emit(this.image);
    this.onChange(this.image);
    this.onTouch(this.image);
    this.isVisible = false;
  };

  handleValidate = () => {
    const canvasEl: HTMLCanvasElement = this.canvas.nativeElement;
    // let image = canvasEl.toDataURL("image/png").replace("image/png", "image/octet-stream");
    this.image = canvasEl.toDataURL('image/png');

    //location.href = image;
    this.exportImage.emit(this.image);
    this.onChange(this.image);
    this.onTouch(this.image);
  };
}
